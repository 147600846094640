import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import ShopSidebar from "../../wrappers/product/ShopSidebar";
import ShopGender from "../product/ShopGender";
import ShopCategories from "../product/ShopCategories";
import ShopTag from "../product/ShopTag";
import ShopTagColor from "../product/ShopTagColor";
import ShopMultiRangeSlider from "../MultiRangeSlider";
import ShopRetailer from "../product/ShopRetailer";
import ShopSize from "../product/ShopSize";
import {
    getIndividualItemArray,
    removeActiveBrandsSort,
    removeActiveColorsSort,
    removeActiveGenderSort, removeActiveRetailersSort,
    removeActiveSizeSort
} from "../../helpers/product";
import {Divider} from "@mui/material";
import {Close} from "@material-ui/icons";
import {newCheckExistingSize} from "../../pages/shop-product/tools";

const FilterMenu = ({ location,
                        pathname,
                         currentQuery,
                         setCurrentQuery,
                         products,
                         getCategorySortParams,
                         getBrandSortParams,
                         getGenderSortParams,
                         getColorSortParams,
                         getSizeSortParams,
                         getPriceSortParams,
                         getRetailerSortParams,
                         sideSpaceClass,
                         sortedHighPrice,
                         sortedLowPrice,
                         uniqueGenders,
                         uniqueSizes,
                         uniqueBrands,
                         uniqueColors,
                         uniqueRetailers,
                         uniqueCategories,
                         setIsOpen
                     }) => {
    const closeMobileMenu = () => {
        setIsOpen(false)
    }

    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(location && location.pathname == "/products/electronic" ? 1500 : 500);

    useEffect(() => {
        console.log(location)
    }, [location])


    const newUniqueSizes = newGetProductsIndividualSizes(pathname, uniqueSizes);
    const uniqueTags = uniqueBrands;
    let uniqueGender = uniqueGenders.filter((gender) => gender !== 'Unisex');
    uniqueGender = uniqueGender.filter((gender) => gender !== '');
    uniqueGender = uniqueGender.filter((gender) => gender !== 'Girls');
    uniqueGender = uniqueGender.filter((gender) => gender !== 'Boys');

    return(
        <div
        style={{
            position: "fixed",
            zIndex: "999999999999",
            top: 0,
            right: 0,
            width: "100%",
            maxWidth: "100%",
            height: "100vh",
            padding: "10px 15px 0",
            transition: "0.6s",
            backgroundColor: "white",
        }}>
            <div>
                <button
                    style={{backgroundColor: "#343538", borderRadius: "50%", color: "white", position: "absolute",
                        right: "15px", top: "30px", border: "1px solid",
                    width: "40px", height: "40px"}}
                    onClick={() => closeMobileMenu()}
                >
                    <Close/>
                </button>
            </div>

            <div style={{
                overflow: "auto",
                height: "100%",
                paddingBottom: "150px"
            }}>
                <h2 style={{fontWeight: 500}}>Filtre</h2>
                <Divider/>
                {/* shop search */}
                {/*<ShopSearch />*/}
                <ShopCategories
                    location={location}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                    categories={uniqueCategories}
                    getSortParams={getCategorySortParams} />
                <Divider style={{marginTop: "10px"}}/>

                {
                    location.pathname != "/products/accessories" && location.pathname != "/products/nutrition" && location.pathname != "/products/electronic"
                        ?
                        <>
                            <ShopGender
                                location={location}
                                genders={uniqueGender}
                                getSortParams={getGenderSortParams}
                                currentQuery={currentQuery}
                                setCurrentQuery={setCurrentQuery}
                            />
                            <Divider style={{marginTop: "10px"}}/>
                        </>


                        : null

                }
                {/* filter by size */}
                {
                    location.pathname != "/products/accessories" && location.pathname != "/products/nutrition" && location.pathname != "/products/electronic"
                        ?
                        <>
                            <ShopSize
                                location={location}
                                currentQuery={currentQuery}
                                setCurrentQuery={setCurrentQuery}
                                sizes={newUniqueSizes}
                                getSortParams={getSizeSortParams} />
                            <Divider style={{marginTop: "10px"}}/>
                        </>


                        : null

                }

                {/* filter by price */}
                <ShopMultiRangeSlider sortedHighPrice={maxPrice}
                                      currentQuery={currentQuery}
                                      setCurrentQuery={setCurrentQuery}
                                      sortedLowPrice={minPrice}
                                      getSortParams={getPriceSortParams}/>
                <Divider />

                {/* filter by tag */}
                <ShopTag
                    location={location}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                    tags={uniqueTags}
                    getSortParams={getBrandSortParams} />
                <Divider style={{marginTop: "10px"}}/>

                <ShopTagColor
                    location={location}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                    colors={uniqueColors}
                    getSortParams={getColorSortParams} />
                <Divider />

                {/* filter by size */}
                <ShopRetailer
                    location={location}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                    retailers={uniqueRetailers}
                    getSortParams={getRetailerSortParams} />
            </div>

            <div style={{
                overflow: 'hidden',
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                backgroundColor: 'white',
                zIndex: 999,
                paddingBottom: '25px',
                paddingTop: '15px',
                borderTop: "2px solid #f7f7f7",
                textAlign:"center",
            }}>
                <button
                    style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius:'15px',
                        padding: "10px 20px",
                        border: "2px solid black",
                        fontSize: "13px",
                        fontWeight: 600,
                        marginRight: '50px'
                    }}
                    onClick={() => closeMobileMenu()}

                >Valider</button>
                <button
                    style={{
                        backgroundColor: "white",
                        color: "black",
                        borderRadius:'15px',
                        padding: "10px 20px",
                        border: "2px solid black",
                        fontSize: "13px",
                        fontWeight: 600
                    }}
                    onClick={() => {
                        getGenderSortParams("gender", "")
                        removeActiveGenderSort();
                        getSizeSortParams("size", "");
                        removeActiveSizeSort();
                        getBrandSortParams("brand", "");
                        removeActiveBrandsSort();
                        getColorSortParams("color", "");
                        removeActiveColorsSort();
                        getRetailerSortParams("retailer", "");
                        removeActiveRetailersSort();
                        getPriceSortParams("price", {min: sortedLowPrice, max: sortedHighPrice})
                        setCurrentQuery({ ...currentQuery, lowDrop: "", highDrop : "",lowWeigh: "", highWeigh : "",lowPrice: "", highPrice : "", filters: "Meilleures ventes", retailers: "", genders: "", sizes: "", brands: "", colors: ""});
                    }}
                >Réinitialiser</button>
            </div>
        </div>
    );
}

FilterMenu.propTypes = {
    getSortParams: PropTypes.func,
    getCategorySortParams: PropTypes.func,
    getGenderSortParams: PropTypes.func,
    getColorSortParams: PropTypes.func,
    getSizeSortParams: PropTypes.func,
    getPriceSortParams: PropTypes.func,
    getRetailerSortParams: PropTypes.func,
    products: PropTypes.array,
    sortedLowPrice: PropTypes.any,
    sortedHighPrice: PropTypes.any,
    sideSpaceClass: PropTypes.string,
    currentQuery: PropTypes.object,
    setCurrentQuery: PropTypes.func,
    location: PropTypes.string
};

// get individual sizes
export const newGetProductsIndividualSizes = (pathname, sizes) => {
    let productSizes = [];

    if (sizes){
        sizes.map(size => {
            if (size !== "questions les plus utiles"
                && size !== "questions les plus récentes"
                && size !== "questions les plus commentées"
                && size !== "" && newCheckExistingSize(pathname, size)) {
                return productSizes.push(size);
            } else {
                return productSizes
            }
        })
    }

    let uniqueSize = [...new Set(productSizes.sort())]
    const individualProductSizes = getIndividualItemArray(uniqueSize);
    return individualProductSizes;

};

export default FilterMenu;
