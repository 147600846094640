import PropTypes from "prop-types";
import React, {forwardRef, Fragment, useEffect, useState} from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import axios from "axios";
import {Col, Row, Spinner} from "react-bootstrap";
import {Divider} from "@mui/material";
import {
    useHistory,
  } from 'react-router-dom';
import {FaCircle} from "react-icons/fa";
import {Capacitor} from "@capacitor/core";
import { getWithExpiry } from "../home/HomeFashionTwo";
import { isMobileOnly } from "react-device-detect";
import TabPhysicalShopsProductElevenInfinite from "../../wrappers/product/TabPhysicalShopsProductElevenInfinite";


const PhysicalShop = ({location}) => {
    const {pathname} = location;
    const history = useHistory();
    const physicalShopID = location.pathname.split("/physical-shop/").pop()

    const [physicalShop, setPhysicalShop] = useState(null)
    const [loading, setLoading] = useState(true)

    const getPhysicalShop = (id) => {
        let query = 'https://public-front-api.therunningcollective.fr/physical-shop?id=' + id

        let trcActivatedGender = getWithExpiry("trc-front-activated-gender")    
        if(trcActivatedGender){
            query = query + "&genders=" + trcActivatedGender
        }

        axios
            .get(query)
            .then((res) => {
                setPhysicalShop(res.data) 
                setLoading(false)            
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)            
            })
    }

    useEffect(() => {
        getPhysicalShop(physicalShopID)
    }, [physicalShopID])

    return (
        <Fragment>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
                {physicalShop && physicalShop.name}
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible"
                       headerContainerClass="container-fluid"
                       headerPaddingClass="header-padding-2"
                       location={location}>
                {/* breadcrumb */}
                <Breadcrumb location={location}/>
                {
                    loading ?
                        <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" role="status" >
                                <span className="visually-hidden"></span>
                            </Spinner>
                        </div>
                    : 
                    <div className={isMobileOnly ? ""  : "container"} style={{height: "100%"}}>
                        <div style={{padding: "0", height: "230px", overflow: "hidden", position: "relative"}}>
                            <div>
                                {
                                    physicalShop && physicalShop.logo ?
                                    <img src={physicalShop.logo} style={{
                                        width:"100%",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)"
                                    }}/>
                                    : null
                                }
                            </div>
                        </div>  
                        <div className="container" style={{paddingTop: "15px"}}>
                            <Row style={{marginBottom: "10px"}}>
                                <Col xs={12}>
                                    <h3 style={{marginBottom: "5px", fontSize: "21px"}}><img style={{ maxWidth: isMobileOnly ? '28px' : "45px", borderRadius: "5px", marginTop: "-5px" }} src={physicalShop.mapLogo}/>&nbsp;&nbsp;{physicalShop.name}</h3>
                                </Col>
                                <Col xs={12}>
                                    <div style={{paddingTop: "5px", color: physicalShop.openingToday.closed && !physicalShop.openingToday.willOpened && !physicalShop.openingToday.willClosed ? "red": physicalShop.openingToday. willClosed || physicalShop.openingToday. willOpened ? "orange": "green" }}>
                                        <FaCircle/> {physicalShop.openingToday.closed && physicalShop.openingToday.hours == "" && !physicalShop.openingToday.willOpened && !physicalShop.openingToday.willClosed ? "Fermé" : physicalShop.openingToday.closed && !physicalShop.openingToday.willOpened && !physicalShop.openingToday.willClosed ? "Fermé - " : physicalShop.openingToday. willOpened ? "Ouvre prochainement - " : physicalShop.openingToday. willClosed ? "Ferme prochainement - " :  "Ouvert - "}<span style={{color: "black"}}>{physicalShop.openingToday.hours}</span>
                                    </div>                                                                        
                                </Col>
                            </Row>
                            {
                                physicalShop.smartProducts && physicalShop.smartProducts.length > 0 ?
                                    <>
                                        <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                                        <Row>
                                            <Col xs={12}>
                                                <h4 style={{marginBottom: "0px"}}>Produits à la une : </h4>
                                            </Col>
                                        </Row>
                                        <div style={{marginLeft: isMobileOnly ? "-15px" : "0", marginRight: isMobileOnly ? "-15px" : "0px"}}>
                                            <TabPhysicalShopsProductElevenInfinite
                                                category="kids"
                                                spaceBottomClass="pb-10"
                                                shopType=""
                                                productsList={physicalShop.smartProducts}
                                                isSide={true}
                                            />
                                        </div>
                                    </>
                                : null
                            }
                            <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                            <Row>
                                <Col xs={12}>
                                    <h4 style={{marginBottom: "0px"}}>Horaires d'ouverture : </h4>
                                </Col>
                                <Col xs={12}>
                                    <div>Lundi : &nbsp; 
                                        {
                                            physicalShop.openingHours.monday.closed ?
                                                <span style={{color : "red"}}>Fermé</span>
                                            : <span>{getOpenningHours(physicalShop.openingHours.monday)}</span>
                                        }
                                    </div>
                                    <div>Mardi : &nbsp; 
                                        {
                                            physicalShop.openingHours.tuesday.closed ?
                                                <span style={{color : "red"}}>Fermé</span>
                                                : <span>{getOpenningHours(physicalShop.openingHours.tuesday)}</span>
                                            }
                                    </div>
                                    <div>Mercredi : &nbsp; 
                                        {
                                            physicalShop.openingHours.wednesday.closed ?
                                                <span style={{color : "red"}}>Fermé</span>
                                                : <span>{getOpenningHours(physicalShop.openingHours.wednesday)}</span>
                                            }
                                    </div>
                                    <div>Jeudi : &nbsp; 
                                        {
                                            physicalShop.openingHours.thursday.closed ?
                                                <span style={{color : "red"}}>Fermé</span>
                                                : <span>{getOpenningHours(physicalShop.openingHours.thursday)}</span>
                                            }
                                    </div>
                                    <div>Vendredi : &nbsp; 
                                        {
                                            physicalShop.openingHours.friday.closed ?
                                                <span style={{color : "red"}}>Fermé</span>
                                                : <span>{getOpenningHours(physicalShop.openingHours.friday)}</span>
                                            }
                                    </div>
                                    <div>Samedi :&nbsp; 
                                        {
                                            physicalShop.openingHours.saturday.closed ?
                                                <span style={{color : "red"}}>Fermé</span>
                                                : <span>{getOpenningHours(physicalShop.openingHours.saturday)}</span>
                                            }
                                    </div>
                                    <div>Dimanche : &nbsp; 
                                        {
                                            physicalShop.openingHours.sunday.closed ?
                                                <span style={{color : "red"}}>Fermé</span>
                                                : <span>{getOpenningHours(physicalShop.openingHours.sunday)}</span>
                                            }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop: "10px"}}>
                                <div className="col-12 pro-details-quality">
                                    <div className="col-12 pro-details-cart ml-0">
                                        <Row>
                                            <Col xs={12}>
                                                <a
                                                    href={null}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                                    onClick={() => {
                                                        window.location.href = "/physical-shop-booking-clinic?physicalShopID=" + physicalShopID
                                                    }}>
                                                        Prendre Rendez-vous
                                                </a>
                                            </Col> 
                                                                            
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                            <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                            <Row>
                                <Col xs={12}>
                                    <h4 style={{marginBottom: "0px"}}>Addresse : </h4>
                                </Col>
                                <Col xs={12}>
                                    <div>{physicalShop.location.address}</div>
                                    <div>{physicalShop.location.zipCode} - {physicalShop.location.town}</div>
                                    <div>{physicalShop.location.country}</div>
                                </Col>
                            </Row>
                            <Row style={{marginTop: "10px"}}>
                                <div className="col-12 pro-details-quality">
                                    <div className="col-12 pro-details-cart ml-0">
                                        <Row>
                                            <Col xs={12}>
                                                <a
                                                    href={null}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                                    onClick={() => {
                                                        openMap(physicalShop.location.coordinates.lat, 
                                                            physicalShop.location.coordinates.long, 
                                                            physicalShop.name)
                                                    }}>
                                                        M'y rendre
                                                </a>
                                            </Col> 
                                                                            
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                            {
                                    physicalShop.productTypes && physicalShop.productTypes.shoes  && physicalShop.productTypes.shoes.length > 0?
                            <Divider style={{marginTop: "10px"}}/> : null }
                            <div style={{ marginTop: "10px"}}>
                                {
                                    physicalShop.productTypes && physicalShop.productTypes.shoes && physicalShop.productTypes.shoes.length > 0 ?
                                        <Row>
                                            <Col xs={12}>
                                                <h4 style={{marginBottom: "10px"}}>Type de produits disponibles : </h4>
                                            </Col>
                                            {
                                                physicalShop.productTypes.shoes && physicalShop.productTypes.shoes.length > 0 ?
                                                <Col xs={4}>
                                                    <div style={{fontSize: "15px"}}>
                                                        Chaussures :
                                                    </div>
                                                    {
                                                        physicalShop.productTypes.shoes.map(p => {
                                                            return(
                                                                <li style={{fontSize: p.length > 14 ? "11px" : "13px"}}>
                                                                    {p}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                                : null
                                            }
                                            {
                                                physicalShop.productTypes.spikes && physicalShop.productTypes.spikes.length > 0 ?
                                                <Col xs={4}>
                                                    <div style={{fontSize: "15px"}}>
                                                        Pointes :
                                                    </div>
                                                    {
                                                        physicalShop.productTypes.spikes.map(p => {
                                                            return(
                                                                <li style={{fontSize: p.length > 14 ? "11px" : "13px"}}>
                                                                    {p}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                                : null
                                            }
                                            {
                                                physicalShop.productTypes.electronic && physicalShop.productTypes.electronic.length > 0 ?
                                                <Col xs={4}>
                                                    <div style={{fontSize: "15px"}}>
                                                        Electronique :
                                                    </div>
                                                    {
                                                        physicalShop.productTypes.electronic.map(p => {
                                                            return(
                                                                <li style={{fontSize: p.length > 14 ? "11px" : "13px"}}>
                                                                    {p}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                                : null
                                            }
                                            {
                                                physicalShop.productTypes.apparel && physicalShop.productTypes.apparel.length > 0 ?
                                                <Col xs={4}>
                                                    <div style={{fontSize: "15px"}}>
                                                        Vêtements :
                                                    </div>
                                                    {
                                                        physicalShop.productTypes.apparel.map(p => {
                                                            return(
                                                                <li style={{fontSize: p.length > 14 ? "11px" : "13px"}}>
                                                                    {p}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                                : null
                                            }
                                            {
                                                physicalShop.productTypes.accessories && physicalShop.productTypes.accessories.length > 0 ?
                                                <Col xs={4}>
                                                    <div style={{fontSize: "15px"}}>
                                                        Accessoires :
                                                    </div>
                                                    {
                                                        physicalShop.productTypes.accessories.map(p => {
                                                            return(
                                                                <li style={{fontSize: p.length > 14 ? "11px" : "13px"}}>
                                                                    {p}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                                : null
                                            }
                                        
                                            {
                                                physicalShop.productTypes.food && physicalShop.productTypes.food.length > 0 ?
                                                <Col xs={4}>
                                                    <div style={{fontSize: "15px"}}>
                                                        Nutrition :
                                                    </div>
                                                    {
                                                        physicalShop.productTypes.food.map(p => {
                                                            return(
                                                                <li style={{fontSize: p.length > 14 ? "11px" : "13px"}}>
                                                                    {p}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                                : null
                                            }
            
                                        </Row>
                                    : null 
                                }
                            </div>
                            {
                                    physicalShop.availableBrands && physicalShop.availableBrands.length > 0 ?
                            <Divider style={{marginTop: "10px"}}/> : null }
                            <div style={{ marginTop: "10px", marginBottom: "40px"}}>
                                {
                                    physicalShop.availableBrands && physicalShop.availableBrands.length > 0 ?
                                        <Row>
                                            <Col xs={12}>
                                                <h4 style={{marginBottom: "10px"}}>Marques disponibles : </h4>
                                            </Col>
                                            {
                                                physicalShop.availableBrands.map(brand => {
                                                    return(
                                                        <Col xs={4}>
                                                            <li style={{fontSize: brand.name.length > 14 ? "11px" : "13px"}}>{brand.name}</li>
                                                        </Col>   
                                                    )
                                                })
                                            }
                                           
                                        </Row>
                                    : null 
                                }
                            </div>
                            
                        </div>
                    </div>
                }

            </LayoutOne>
        </Fragment>
    );
};

PhysicalShop.propTypes = {
    location: PropTypes.object
};

export default PhysicalShop;

function getOpenningHours(openningHoursObject) {
    if(openningHoursObject){
        if(openningHoursObject.morning.begin == "" && openningHoursObject.morning.end == ""){
            if(openningHoursObject.evening.begin != "" && openningHoursObject.evening.end != ""){
                return openningHoursObject.evening.begin + " - " + openningHoursObject.evening.end
            }
        } else if (openningHoursObject.morning.end == "" && openningHoursObject.evening.begin == ""){
            return openningHoursObject.morning.begin + " - " + openningHoursObject.evening.end
        }

        return openningHoursObject.morning.begin + " - " + openningHoursObject.morning.end  + " / " + openningHoursObject.evening.begin + " - " + openningHoursObject.evening.end
    }

    return ""
}

export function openMap(lat, long) {
    window.open('https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=' + lat + ',' + long);
}