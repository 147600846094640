import PropTypes from "prop-types";
import React, {useState, useEffect } from "react";
import { changeLanguage } from "redux-multilanguage";
import { useTranslation } from 'react-i18next';

const LanguageChangerFooter = ({
}) => {
  const [currentLanguageCode, setCurrentLanguageCode] = useState("fr")
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    window.open("https://app.therunningcollective."+lng, '_blank')
  };

  useEffect(() => {
      setCurrentLanguageCode(i18n.language)
  }, [])

  return (
    <div className="language-currency-wrap">
      <div className="same-language-currency language-style">
        <span>
          {currentLanguageCode === "en"
            ? "GBP - English"
              : currentLanguageCode === "it"
                  ? "EUR - Italiano"
            : currentLanguageCode === "fr"
            ? "EUR - Français"
            : currentLanguageCode === "es"
            ? "EUR - Español"
            : currentLanguageCode === "de"
            ? "EUR - Germany"
            : ""}{" "}
          <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="en" onClick={e => changeLanguage('co.uk')}>
                GBP - English
              </button>
            </li>
            <li>
              <button value="de" onClick={e => changeLanguage('es')}>
                EUR - Español
              </button>
            </li>
            <li>
              <button value="fr" onClick={e => changeLanguage('fr')}>
                EUR - Français
              </button>
            </li>
            <li>
              <button value="it" onClick={e => changeLanguage('it')}>
                EUR - Italiano
              </button>
            </li>
            <li>
              <button value="de" onClick={e => changeLanguage('de')}>
                EUR - Germany
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

LanguageChangerFooter.propTypes = {
  dispatch: PropTypes.func
};

export default LanguageChangerFooter;
