import PropTypes from "prop-types";
import React, {useRef, Fragment, useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ReactGA from "react-ga4";
import axios from "axios";
import parse from "react-html-parser";
import dayjs from 'dayjs';
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {
    BsCalendarMonth,
    BsClock,
    BsPinMap,
    BsStar,
    BsStarFill,
    CgWebsite,
    FaMountain, FaRunning,
    HiOutlineLightBulb,
    IoMdPricetags
} from "react-icons/all";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {apiConversionRequest, getClientTokenData, GetUserData} from "../../App";
import TokenSignInModal from "../../components/product/TokenSignInModal";
import {HeartBroken} from "@mui/icons-material";
import ReactPixel from "react-facebook-pixel";
import ForecastModal from "../../components/header/ForecastModal";
import { FaCheckCircle } from "react-icons/fa";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { FaTrophy } from "react-icons/fa";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import qs from "query-string";
import JoinForecastLeagueModal from "../../components/header/JoinForecastLeagueModal";
import { FiBookOpen } from "react-icons/fi";
import ForecastRuleModal from "../../components/header/ForecastRuleModal";
import i18n from "../../helpers/i18n";
import { isMobileOnly } from "react-device-detect";
import { Grid, TextField } from "@mui/material";

const BecomeAmbassador = ({location}) => {
    const {pathname} = location;
    let slug = location.pathname.split("/forecast/").pop()
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const [forecast, setForecast] = useState({})
    const [headerPadding, setHeaderPadding] = useState("10px")
    const [headerHomePadding, setHeaderHomePadding] = useState("0px")

    const [shoeSize, setShoeSize] = useState('');
    const [birthday, setBirthday] = useState('');
    const [favoriteDistance, setFavoriteDistance] = useState('');
    const [apparelSize, setApparelSize] = useState('');
    const [facebookLink, setFacebookLink] = useState('');
    const [instagramLink, setInstagramLink] = useState('');
    const [motivation, setMotivation] = useState('');
    const [work, setWork] = useState('');
    const [tiktokLink, setTiktokLink] = useState('');
    const [linkedInLink, setLinkedInLink] = useState('');
    const [raceResultsLink, setRaceResultsLink] = useState('');
    const [user, setUser] = useState(null);
    const [existingUser, setExistingUser] = useState(getClientTokenData());

    const [errorGender, setErrorGender] = useState(null);
    const [errorFirstName, setErrorFirstName] = useState(null);
    const [errorLastName, setErrorLastName] = useState(null);
    const [errorEmail, setErrorEmail] = useState(null);
    const [errorType, setErrorType] = useState(null);
    const [errorPhoneNumber, setErrorPhoneNumber] = useState(null);
    const [errorBirthday, setErrorBirthday] = useState(null);
    const [errorDistanceFavorite, setErrorDistanceFavorite] = useState(null);
    const [errorShoeSize, setErrorShoeSize] = useState(null);
    const [errorApparelSize, setErrorApparelSize] = useState(null);
    const [errorInstagramLink, setErrorInstagramLink] = useState(null);
    const [errorTiktokLink, setErrorTiktokLink] = useState(null);
    const [errorMotivation, setErrorMotivation] = useState(null);
    const [errorWork, setErrorWork] = useState(null);

    const [errorTown, setErrorTown] = useState(null);


    const [type, setType] = useState('');
    const [gender, setGender] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userID, setUserID] = useState('');
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newsletter, setNewsletter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [clubName, setClubName] = useState('');
    const [town, setTown] = useState('');



    const [loadingUser, setLoadingUser] = useState(true);

    const [testingApplied, setTestingApplied] = useState(false);

    const getProfile = () => {
        let query = 'https://public-front-api.therunningcollective.fr/profile'
        axios.get(query, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,}
        )
            .then((res) => {
                setUser(res.data);
                setUserID(res.data.id);
                setGender(res.data.gender)
                setFirstName(res.data.firstName)
                setLastName(res.data.name);
                setType(res.data.type);
                setTown(res.data.town);
                setShoeSize(res.data.shoeSize)
                setApparelSize(res.data.apparelSize)
                setLoadingUser(false);
                setFavoriteDistance(res.data.favoriteDistance)
                setBirthday(res.data.birthdayDate)
                setInstagramLink(res.data.instagramLink)
                setFacebookLink(res.data.facebookLink)
                setLinkedInLink(res.data.linkedInLink)
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.data && error.response.data.message){
                        localStorage.removeItem('trc-client-token');
                        window.location.href="/sign-in";
                    }
                }
            });
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (gender === ""){
            setErrorGender("Veuillez sélectionner votre sexe.")
        } else {
            setErrorGender(null)
        }
        if (firstName === ""){
            setErrorFirstName("Veuillez écrire votre prénom.")
        } else {
            setErrorFirstName(null)
        }
        if (lastName === ""){
            setErrorLastName("Veuillez écrire votre nom de famille.")
        } else {
            setErrorFirstName(null)
        }
        if (birthday === ""){
            setErrorBirthday("Veuillez écrire votre date de naissance.")
        } else {
            setErrorBirthday(null)
        }
        const emailRegex=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (email === ""){
            setErrorEmail("Veuillez écrire votre email.")
        } else {
            if (!emailRegex.test(email)){
                setErrorEmail("Veuillez remplir une addresse mail valide")
            } else {
                setErrorEmail(null)
            }
        }
        if (phoneNumber === ""){
            setErrorPhoneNumber("Veuillez écrire votre numéro de téléphone.")
        } else {
            setErrorPhoneNumber(null)
        }
        if (type === ""){
            setErrorType("Veuillez sélectionner votre type de pratique.")
        } else {
            setErrorType(null)
        }
        if (favoriteDistance === ""){
            setErrorDistanceFavorite("Veuillez sélectionner votre distance favorite.")
        } else {
            setErrorDistanceFavorite(null)
        }
        if (shoeSize === ""){
            setErrorShoeSize("Veuillez sélectionner votre taille.")
        } else {
            setErrorShoeSize(null)
        }
        if (apparelSize === ""){
            setErrorApparelSize("Veuillez sélectionner votre taille.")
        } else {
            setErrorApparelSize(null)
        }
        if (instagramLink === ""){
            setErrorInstagramLink("Veuillez rentrer le lien de votre profil instagram.")
        } else {
            setErrorInstagramLink(null)
        }
        if (town === ""){
            setErrorTown("Veuillez rentrer le nom de ta ville.")
        } else {
            setErrorTown(null)
        }
        if (motivation === ""){
            setErrorMotivation("Veuillez rentrer vos motivations.")
        } else {
            setErrorMotivation(null)
        }
        if (work === ""){
            setErrorWork("Veuillez nous expliquer ce que vous faites dans la vie.")
        } else {
            setErrorWork(null)
        }

        if(gender !== "" && firstName !== "" && lastName !== "" && type !== "" && shoeSize !== "" && apparelSize !== "" && 
            birthday !== "" && favoriteDistance !== "" && instagramLink !== "" && motivation !== "" && work !== "" && town !== ""){
            let infos = {
                gender: gender,
                email: email,
                lastName: lastName,
                firstName: firstName,
                phoneNumber: phoneNumber,
                type: type,
                userID : userID,
                shoeSize: shoeSize,
                apparelSize: apparelSize,
                birthdayDate: new Date(birthday),
                favoriteDistance: favoriteDistance,
                isAmbassadorApplied: true,
                motivation: motivation,
                instagramLink: instagramLink,
                town: town,
                facebookLink: facebookLink,
                tiktokLink: tiktokLink,
                linkedInLink: linkedInLink,
                raceResultsLink: raceResultsLink,
                work: work,
            }
            
    
            setLoading(true)
            submitBecomeAmbassador(infos);
        }
        
    };

    const submitBecomeAmbassador = (user) => {
        ReactGA.event({
            category: 'User',
            action: 'Submit become ambassador'
        });

        setLoading(true);
        let query = 'https://public-front-api.therunningcollective.fr/become-ambassador'
        axios.post(query, JSON.stringify(user), {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,}).then((res) => {
            console.log("done");
            window.location.reload()
        })
            .catch((err) => {
                console.log(err.response)
            })
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
        if(localStorage.getItem('trc-client-token')){
            getProfile();
        }
    }, [])
    
    return (
        <Fragment>
            <MetaTags>
                <title>Devenir ambassadeur TRC</title>
                <meta
                    name="description"
                    content={"Rejoignez The Running Collective en tant qu'ambassadeur et inspirez la communauté running ! Partagez votre passion pour la course, accédez à des avantages exclusifs, et contribuez à promouvoir un style de vie actif. Découvrez comment devenir ambassadeur dès aujourd'hui !"}
                />
                <meta property="og:description" content={"Rejoignez The Running Collective en tant qu'ambassadeur et inspirez la communauté running ! Partagez votre passion pour la course, accédez à des avantages exclusifs, et contribuez à promouvoir un style de vie actif. Découvrez comment devenir ambassadeur dès aujourd'hui !"}
                />
                <meta property="og:type" content="game" />
                <meta property="og:local" content={i18n.language == "en" ? "gb_GB" : i18n.language + "_"+ i18n.language.toUpperCase()} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={"Devenir ambassadeur TRC"} />
                <meta property="og:image" content={"https://blog.therunningcollective.fr/wp-content/uploads/2023/03/Sans-titre-2.001.jpeg"}/>
            </MetaTags>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Accueil</BreadcrumbsItem>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
                Devenir ambassadeur TRC
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible"
                       headerContainerClass="container-fluid"
                       headerPaddingClass="header-padding-2"
                       location={location}>
                {/* breadcrumb */}
                <Breadcrumb />

                <div className={isMobileOnly ? ""  : "container"} style={{height: "100%"}}>
                    {
                        !existingUser ? 
                        <TokenSignInModal
                            show={true}
                        /> : loadingUser ?
                        <Row style={{marginTop: "30px", textAlign:"center"}}>
                            <Col xs={12}>
                                <div style={{textAlign: 'center'}}>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                    </Spinner>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div style={{textAlign: 'center', marginTop: "20px", marginBottom: "35px"}}>
                                    Chargement de votre profil...
                                </div>
                            </Col>
                        </Row>
                        :  user && user.isAmbassadorApplied ?
                        <Row style={{marginTop: "30px", textAlign:"center"}}>
                            <Col xs={12}>
                                <div style={{textAlign: 'center'}}>
                                    <FaCheckCircle style={{color: "green"}} size={"3rem"}/>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div style={{textAlign: 'center', marginTop: "20px", marginBottom: "35px"}}>
                                    Votre candidature a été reçue et est en cours d'analyse :)
                                </div>
                            </Col>
                        </Row>
                        : loading ?
                        <Row style={{marginTop: "30px", textAlign:"center"}}>
                            <Col xs={12}>
                                <div style={{textAlign: 'center'}}>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                    </Spinner>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div style={{textAlign: 'center', marginTop: "20px", marginBottom: "35px"}}>
                                    Enregistrement de votre candidature...
                                </div>
                            </Col>
                        </Row> : 
                    <div className="container" style={{paddingTop: headerPadding}}>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                            <div style={{fontSize: "23px", marginBottom: "30px"}}>Informations personnelles : </div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    {
                                        errorFirstName ?
                                            <TextField
                                                error
                                                name="firstName"
                                                required
                                                fullWidth
                                                id="firstName"
                                                label="Prénom"
                                                defaultValue={user.firstName}
                                                onChange={(event => {
                                                    setFirstName(event.target.value)
                                                })}
                                                helperText={errorFirstName}
                                            />
                                            :
                                            <TextField
                                                name="firstName"
                                                required
                                                fullWidth
                                                id="firstName"
                                                label="Prénom"
                                                defaultValue={user.firstName}
                                                onChange={(event => {
                                                    setFirstName(event.target.value)
                                                })}
                                            />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {
                                        errorLastName ?
                                            <TextField
                                                error
                                                name="lastName"
                                                required
                                                fullWidth
                                                id="lastName"
                                                label="Nom"
                                                defaultValue={user.name}
                                                onChange={(event => {
                                                    setLastName(event.target.value)
                                                })}
                                                helperText={errorLastName}
                                            />
                                            :
                                            <TextField
                                                required
                                                fullWidth
                                                id="lastName"
                                                label="Nom"
                                                name="lastName"
                                                defaultValue={user.name}
                                                onChange={(event => {
                                                    setLastName(event.target.value)
                                                })}
                                            />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {
                                        errorGender ?
                                            <TextField
                                                error
                                                required
                                                fullWidth
                                                id="gender"
                                                select
                                                label="Sexe"
                                                value={gender ? gender : user.gender}
                                                onChange={(event => {
                                                    setGender(event.target.value)
                                                })}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                helperText={errorGender}
                                            >
                                                <option key="" value=""></option>
                                                <option key="Homme" value="Homme">Homme</option>
                                                <option key="Femme" value="Femme">Femme</option>
                                                <option key="Autre" value="Autre">Autre</option>
                                            </TextField>
                                            :
                                            <TextField
                                                required
                                                fullWidth
                                                id="gender"
                                                select
                                                label="Sexe"
                                                value={gender ? gender : user.gender}
                                                onChange={(event => {
                                                    setGender(event.target.value)
                                                })}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                            >
                                                <option key="" value=""></option>
                                                <option key="Homme" value="Homme">Homme</option>
                                                <option key="Femme" value="Femme">Femme</option>
                                                <option key="Autre" value="Autre">Autre</option>
                                            </TextField>
                                    }

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {errorType ?
                                        <TextField
                                            error
                                            required
                                            fullWidth
                                            id="type"
                                            select
                                            label="Type de pratique"
                                            value={type ? type : user.type}
                                            onChange={(event => {
                                                setType(event.target.value)
                                            })}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            helperText={errorType}
                                        >
                                            <option key="" value=""></option>
                                            <option key="Athlétisme"
                                                    value="Athlétisme">Athlétisme
                                            </option>
                                            <option key="Running" value="Running">Running
                                            </option>
                                            <option key="Trail" value="Trail">Trail</option>
                                        </TextField>
                                        :
                                        <TextField
                                            required
                                            fullWidth
                                            id="type"
                                            select
                                            label="Type de pratique"
                                            value={type ? type : user.type}
                                            onChange={(event => {
                                                setType(event.target.value)
                                            })}
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            <option key="" value=""></option>
                                            <option key="Athlétisme"
                                                    value="Athlétisme">Athlétisme
                                            </option>
                                            <option key="Running" value="Running">Running
                                            </option>
                                            <option key="Trail" value="Trail">Trail</option>
                                        </TextField>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {
                                        errorBirthday ?
                                            <TextField
                                                error
                                                name="birthday"
                                                required
                                                fullWidth
                                                id="birthday"
                                                label="Date d'anniversaire"
                                                InputLabelProps={{ shrink: true, required: true }}
                                                type="date"
                                                defaultValue={dayjs(user.birthdayDate).format("YYYY-MM-DD")}
                                                onChange={(event => {
                                                    setBirthday(event.target.value)
                                                })}
                                                helperText={errorLastName}
                                            />
                                            :
                                            <TextField
                                                required
                                                fullWidth
                                                name="birthday"
                                                id="birthday"
                                                label="Date d'anniversaire"
                                                InputLabelProps={{ shrink: true, required: true }}
                                                type="date"
                                                defaultValue={
                                                user.birthdayDate && user.birthdayDate !== "0001-01-01T00:00:00Z" ? dayjs(user.birthdayDate).format("YYYY-MM-DD")
                                            : dayjs(new Date()).format("YYYY-MM-DD")}
                                                onChange={(event => {
                                                    setTestingApplied(true)
                                                    setBirthday(event.target.value)
                                                })}/>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} style={{paddingTop: "15px"}}>
                                    {errorTown ?
                                        <TextField
                                            fullWidth
                                            error
                                            name="Town"
                                            id="Town"
                                            label="Nom de ta ville"
                                            placeholder="Toulouse"
                                            helperText={errorTown}
                                            defaultValue={town ? town : user.town ? user.town : ""}
                                            onChange={(event => {
                                                setTown(event.target.value)
                                            })}
                                            type="text"
                                        />
                                        :
                                        <TextField
                                            required
                                            fullWidth
                                            name="Town"
                                            id="Town"
                                            label="Nom de ta ville"
                                            placeholder="Toulouse"
                                            type="text"
                                            defaultValue={town ? town : user.town ? user.town : ""}
                                            onChange={(event => {
                                                setTestingApplied(true)
                                                setTown(event.target.value)
                                            })}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {
                                        errorDistanceFavorite ?
                                            <TextField
                                                error
                                                required
                                                fullWidth
                                                id="type"
                                                select
                                                label="Distance favorite"
                                                value={favoriteDistance ? favoriteDistance : user.favoriteDistance}
                                                onChange={(event => {
                                                    setFavoriteDistance(event.target.value)
                                                })}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                helperText={errorDistanceFavorite}
                                            >
                                                <option key="" value=""></option>
                                                {
                                                    ConstructDistance(type ? type : user.type ? user.type : "").map((size, index) => {
                                                        return(
                                                            <option key={index} value={size}>{size}</option>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                            :
                                            <TextField
                                                required
                                                fullWidth
                                                id="type"
                                                select
                                                label="Distance favorite"
                                                value={favoriteDistance ? favoriteDistance : user.favoriteDistance}
                                                onChange={(event => {
                                                    setFavoriteDistance(event.target.value)
                                                })}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                            >
                                                <option key="" value=""></option>
                                                {
                                                    ConstructDistance(type ? type : user.type ? user.type : "").map((size, index) => {
                                                        return(
                                                            <option key={index} value={size}>{size}</option>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} style={{paddingTop:"15px"}}>
                                    {
                                        errorShoeSize ?
                                            <TextField
                                                required
                                                error
                                                fullWidth
                                                id="type"
                                                select
                                                label="Taille de chaussures"
                                                value={shoeSize ? shoeSize : user.shoeSize}
                                                onChange={(event => {
                                                    setShoeSize(event.target.value)
                                                })}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                helperText={errorShoeSize}
                                            >
                                                <option key="" value=""></option>
                                                {
                                                    GetAvailableSizes().map((size, index) => {
                                                        return(
                                                            <option key={index} value={size}>{size}</option>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                            :
                                            <TextField
                                                required
                                                fullWidth
                                                id="type"
                                                select
                                                label="Taille de chaussures"
                                                value={shoeSize ? shoeSize : user.shoeSize}
                                                onChange={(event => {
                                                    setTestingApplied(true)
                                                    setShoeSize(event.target.value)
                                                })}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                            >
                                                <option key="" value=""></option>
                                                {
                                                    GetAvailableSizes().map((size, index) => {
                                                        return(
                                                            <option key={index} value={size}>{size}</option>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{paddingTop: "15px"}}>
                                {
                                    errorApparelSize ?
                                        <TextField
                                            required
                                            error
                                            fullWidth
                                            id="type"
                                            select
                                            label="Taille de vêtements"
                                            value={apparelSize ? apparelSize : user.apparelSize}
                                            onChange={(event => {
                                                setTestingApplied(true)
                                                setApparelSize(event.target.value)
                                            })}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            helperText={errorApparelSize}
                                        >
                                            <option key="" value=""></option>
                                            <option key="0" value="XXS">XXS</option>
                                            <option key="1" value="XS">XS</option>
                                            <option key="2" value="S">S</option>
                                            <option key="3" value="M">M</option>
                                            <option key="4" value="L">L</option>
                                            <option key="5" value="XL">XL</option>
                                            <option key="6" value="XXL">XXL</option>
                                        </TextField>
                                        :
                                        <TextField
                                            required
                                            fullWidth
                                            id="type"
                                            select
                                            label="Taille de vêtements"
                                            value={apparelSize ? apparelSize : user.apparelSize}
                                            onChange={(event => {
                                                setApparelSize(event.target.value)
                                            })}
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            <option key="" value=""></option>
                                            <option key="0" value="XXS">XXS</option>
                                            <option key="1" value="XS">XS</option>
                                            <option key="2" value="S">S</option>
                                            <option key="3" value="M">M</option>
                                            <option key="4" value="L">L</option>
                                            <option key="5" value="XL">XL</option>
                                            <option key="6" value="XXL">XXL</option>
                                        </TextField>
                                }
                            </Grid>
                            <Grid item xs={12} style={{paddingTop: "15px"}}>
                                {errorWork ?
                                    <TextField
                                        fullWidth
                                        multiline
                                        required
                                        error
                                        inputProps={{ maxLength: 200 }}
                                        name="work"
                                        id="work"
                                        label="Quelle est ta profession ?"
                                        placeholder="Décris nous ce que tu fais dans la vie :)"
                                        helperText={errorWork}
                                        defaultValue={work ? work : user.work ? user.work : ""}
                                        onChange={(event => {
                                            setWork(event.target.value)
                                        })}
                                        rows={4}
                                        type="text"
                                    />
                                    :
                                    <TextField
                                        fullWidth
                                        required
                                        multiline
                                        inputProps={{ maxLength: 200 }}
                                        name="work"
                                        id="work"
                                        label="Quelle est ta profession ?"
                                        placeholder="Décris nous ce que tu fais dans la vie :)"
                                        helperText={errorWork}
                                        defaultValue={work ? work : user.work ? user.work : ""}
                                        type="text"
                                        rows={4}
                                        onChange={(event => {
                                            setTestingApplied(true)
                                            setWork(event.target.value)
                                        })}
                                    />
                                }
                            </Grid>
                            <div style={{fontSize: "23px", marginTop: "20px"}}>Réseaux sociaux : </div>
                            <Grid item xs={12} sm={6} style={{paddingTop: "15px"}}>
                                {errorInstagramLink ?
                                    <TextField
                                        fullWidth
                                        error
                                        name="instagramLink"
                                        id="instagramLink"
                                        label="Lien de ton compte Instagram"
                                        placeholder="https://instagram.com/temporunclub"
                                        helperText={errorInstagramLink}
                                        defaultValue={instagramLink ? instagramLink : user.instagramLink ? user.instagramLink : ""}
                                        onChange={(event => {

                                            setInstagramLink(event.target.value)
                                        })}
                                        type="text"
                                    />
                                    :
                                    <TextField
                                        required
                                        fullWidth
                                        name="instagramLink"
                                        id="instagramLink"
                                        label="Lien de ton compte Instagram"
                                        placeholder="https://instagram.com/temporunclub"
                                        type="text"
                                        defaultValue={instagramLink ? instagramLink : user.instagramLink ? user.instagramLink : ""}
                                        onChange={(event => {
                                            setTestingApplied(true)
                                            setInstagramLink(event.target.value)
                                        })}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} style={{paddingTop: "15px"}}>
                                {errorTiktokLink ?
                                    <TextField
                                        fullWidth
                                        error
                                        name="TiktokLink"
                                        id="TiktokLink"
                                        label="Lien de ton compte Tiktok"
                                        placeholder="https://www.tiktok.com/@therunningcollective"
                                        helperText={errorTiktokLink}
                                        defaultValue={tiktokLink ? tiktokLink : user.tiktokLink ? user.tiktokLink : ""}
                                        onChange={(event => {
                                            setTiktokLink(event.target.value)
                                        })}
                                        type="text"
                                    />
                                    :
                                    <TextField
                                        fullWidth
                                        name="TiktokLink"
                                        id="TiktokLink"
                                        label="Lien de ton compte Tiktok"
                                        placeholder="https://www.tiktok.com/@therunningcollective"
                                        type="text"
                                        defaultValue={tiktokLink ? tiktokLink : user.tiktokLink ? user.tiktokLink : ""}
                                        onChange={(event => {
                                            setTestingApplied(true)
                                            setTiktokLink(event.target.value)
                                        })}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} style={{paddingTop: "15px"}}>
                                <TextField
                                    fullWidth
                                    name="facebookLink"
                                    id="facebookLink"
                                    label="Lien de ton compte Facebook"
                                    placeholder="https://facebook.com/temporunclub"
                                    type="text"
                                    defaultValue={facebookLink ? facebookLink : user.facebookLink ? user.facebookLink : ""}
                                    onChange={(event => {
                                        setTestingApplied(true)
                                        setFacebookLink(event.target.value)
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} style={{paddingTop: "15px"}}>
                                <TextField
                                    fullWidth
                                    name="linkedInLink"
                                    id="linkedInLink"
                                    label="Lien de ton compte LinkedIn"
                                    placeholder="https://linkedin.com/temporunclub"
                                    type="text"
                                    defaultValue={linkedInLink ? linkedInLink : user.linkedInLink ? user.linkedInLink : ""}
                                    onChange={(event => {
                                        setTestingApplied(true)
                                        setLinkedInLink(event.target.value)
                                    })}
                                />
                            </Grid>
                            <div style={{fontSize: "23px", marginTop: "20px"}}>Pourquoi devenir ambassadeur ? </div>
                            <Grid item xs={12} style={{paddingTop: "15px"}}>
                                {errorMotivation ?
                                    <TextField
                                        required
                                        fullWidth
                                        multiline
                                        error
                                        inputProps={{ maxLength: 200 }}
                                        name="motivation"
                                        id="motivation"
                                        label="Montre-nous ta motivation !"
                                        placeholder="Convaincs-nous en 200 mots ou moins ! Nous recherchons des coureurs capables d'inspirer les autres, alors montre-nous ta motivation :)"
                                        helperText={errorMotivation}
                                        defaultValue={motivation ? motivation : user.motivation ? user.motivation : ""}
                                        onChange={(event => {
                                            setMotivation(event.target.value)
                                        })}
                                        rows={4}
                                        type="text"
                                    />
                                    :
                                    <TextField
                                        required
                                        fullWidth
                                        multiline
                                        inputProps={{ maxLength: 200 }}
                                        name="motivation"
                                        id="motivation"
                                        label="Montre-nous ta motivation !"
                                        placeholder="Convaincs-nous en 200 mots ou moins ! Nous recherchons des coureurs capables d'inspirer les autres, alors montre-nous ta motivation :)"
                                        helperText={errorMotivation}
                                        defaultValue={motivation ? motivation : user.motivation ? user.motivation : ""}
                                        type="text"
                                        rows={4}
                                        onChange={(event => {
                                            setTestingApplied(true)
                                            setMotivation(event.target.value)
                                        })}
                                    />
                                }
                            </Grid>
                            <div style={{fontSize: "16px", marginTop: "20px",padding: "10px", backgroundColor: "lightgrey", borderRadius: "10px"}}>
                                <div><HiOutlineLightBulb size={"1.4rem"}/> Dernier petit tips :</div>
                                N'oublie pas d'identifier @temporunclub dans tes publications pour augmenter tes chances d'être sélectionné(e) :)
                            </div>


                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                    style={{backgroundColor: "black",
                                        width: "100%",
                                        color : "white",
                                        justifyContent: "center", display: "inline-flex", position: "relative", boxSizing: "border-box",
                                        padding: "6px 16px", borderRadius: "4px", fontWeight: 500, marginTop: "24px", marginBottom: "16px"}}
                                >
                                    Postuler
                                </Button>
                            </Grid>
                        </Box>
                    </div>
                    }
                </div>
            </LayoutOne>
        </Fragment>
    );
};

BecomeAmbassador.propTypes = {
    location: PropTypes.object
};

export default BecomeAmbassador;


export function GetAvailableSizes(){
    return [
        "36",
        "36½",
        "37",
        "37½",
        "38",
        "38½",
        "39",
        "39½",
        "40",
        "40½",
        "41",
        "41½",
        "42",
        "42½",
        "43",
        "43½",
        "44",
        "44½",
        "45",
        "45½",
        "46",
        "46½",
        "47",
        "47½",
        "48",
        "48½",
        "49",
        "49½",
        "50",
        "50½",
        "51",
        "51½"
    ]
}

export const ConstructDistance = (type) => {
    if (type.includes("Athlétisme")){
        return [...distanceRunning, ...distanceAthletisme]
    } else if (type.includes("Running")){
        return distanceRunning
    } else if (type.includes("Trail")){
        return distanceTrail
    } else if (type.includes("Triathlon")){
        return distanceTriathlon
    } else {
        return [...distanceRunning, ...distanceTriathlon, ...distanceAthletisme]
    }
}

const distanceRunning = [
    "5km",
    "10km",
    "20km",
    "Semi-Marathon",
    "Marathon",
    "100km"
]

const distanceTrail = [
    "- de 10km",
    "de 10 à 20km",
    "entre 20 et 40km",
    "de 40 à 80km",
    "80km et +"
]

const distanceTriathlon = [
    "XXS",
    "XS",
    "S",
    "M",
    "L",
    "XL",
    "XXL"
]

const distanceAthletisme = [
    "50m",
    "60m",
    "50m haies",
    "60m haies",
    "100m",
    "100m haies",
    "110m haies",
    "200m",
    "400m",
    "400m haies",
    "800m",
    "1000m",
    "1000m marche",
    "1500m",
    "Mile",
    "2000m",
    "2000m marche",
    "3000m",
    "3000m marche",
    "3000m Steeple",
    "2 Miles",
    "5000m",
    "5000m marche",
    "10000m",
    "10000m marche",
    "Disque",
    "Marteau",
    "Javelot",
    "Poids",
    "Hauteur",
    "Longueur",
    "Triple-saut"
]