import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {getHighPrice, getLowPrice, getShowProducts, getSortedProducts} from '../../helpers/product';
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import ShopSidebar from '../../wrappers/product/ShopSidebar';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import ShopProducts from '../../wrappers/product/ShopProducts';
import axios from "axios";
import {multilanguage} from "redux-multilanguage";
import {isTablet, isMobileOnly} from 'react-device-detect';
import ReactGA from "react-ga4";
import {Col, Row, Spinner} from "react-bootstrap";
import qs from "query-string";
import {useHistory} from "react-router";
import {Helmet} from "react-helmet";
import {apiConversionRequest, getClientTokenData, GetUserData} from "../../App";
import ReactPixel from "react-facebook-pixel";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from "react-i18next";
import unslug from "unslug";
import ShopFilter from "../../components/product/ShopFilter";
import {Divider} from "@mui/material";
import Box from "@mui/material/Box";
import {StyledTab, StyledTabs} from "../../wrappers/header/HeaderTwo";
import {AiOutlineExport, BsChevronDown, BsChevronUp, CiExport, GiRunningShoe, IoCloudDownloadOutline, IoResizeSharp, MdDensitySmall, MdLockReset, RiFolderUploadLine} from "react-icons/all";
import FilterMenu from "../../components/header/FilterMenu";
import shuffleArray from "../../wrappers/brand-logo/BrandLogoSliderOne"
import {getAvailablesSizes} from "../shop-product/tools"
import { getWithExpiry } from "../home/HomeFashionTwo";
import ExportProductsModal from "../../components/header/ExportProductsModal";


const ShopGridFullWidthFavorite = ({location, origin}) => {
    const [layout, setLayout] = useState('grid three-column');
    const [sortType, setSortType] = useState('filterSort');
    const [categorySortType, setCategorySortType] = useState('');
    const [categorySortValue, setCategorySortValue] = useState('');
    const [brandSortType, setBrandSortType] = useState('');
    const [brandSortValue, setBrandSortValue] = useState('');
    const [genderSortType, setGenderSortType] = useState('');
    const [genderSortValue, setGenderSortValue] = useState('');
    const [colorSortType, setColorSortType] = useState('');
    const [colorSortValue, setColorSortValue] = useState('');
    const [sizeSortType, setSizeSortType] = useState('');
    const [retailerSortType, setRetailerSortType] = useState('');
    const [retailerSortValue, setRetailerSortValue] = useState('');

    const [weighSortType, setWeighSortType] = useState('');
    const [weighSortValue, setWeighSortValue] = useState(null);
    const [dropSortType, setDropSortType] = useState('');
    const [dropSortValue, setDropSortValue] = useState(null);
    const [filtersSortType, setFiltersSortType] = useState('');
    const [filtersSortValue, setFiltersSortValue] = useState(null);

    const [sizeSortValue, setSizeSortValue] = useState('');
    const [priceSortType, setPriceSortType] = useState('');
    const [priceSortValue, setPriceSortValue] = useState(null);

    const [sortValue, setSortValue] = useState('default');
    const [filterSortType, setFilterSortType] = useState('');
    const [filterSortValue, setFilterSortValue] = useState('');
    const [pageLimit, setPageLimit] = useState(15);

    const [currentData, setCurrentData] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [sortedHighPrice, setSortedHighPrice] = useState(null);
    const [sortedLowPrice, setSortedLowPrice] = useState(null);
    const [sortedHighWeigh, setSortedHighWeigh] = useState(null);
    const [sortedLowWeigh, setSortedLowWeigh] = useState(null);
    const [sortedHighDrop, setSortedHighDrop] = useState(null);
    const [sortedLowDrop, setSortedLowDrop] = useState(null);
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const [currentQuery, setCurrentQuery] = useState(query);

    const {pathname} = location;
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [])

    const [initialProducts, setInitialProducts] = useState([]);
    const [availableSizes, setAvailableSizes] = useState([]);
    const [availableBrands, setAvailableBrands] = useState([]);
    const [availableColors, setAvailableColors] = useState([]);
    const [availableRetailers, setAvailableRetailers] = useState([]);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [genders, setGenders] = useState([]);
    const [countOfProducts, setCountOfProducts] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [favoriteProducts, setFavoriteProducts] = useState([]);

    const [products, setProducts] = useState({ products: [] });
    const [error, setError] = useState('');
    const [loading, setloading] = useState(true);

    const [isAmbassador, setIsAmbassador] = useState(false);

    useEffect(() => {
      let token = getClientTokenData();
      if (token && token.isAmbassador){
        setIsAmbassador(true)
      }
    }, [])

    let parentCategory = location.pathname.split("/products").pop()

    const [isSmallScreen, setSmallScreen] = useState(false);

    let subCategory = query && query.category ? query.category : ''

    const history = useHistory();
    const handleClick = (pathname, currentQuery) => history.push({
        pathname: pathname,
        search: currentQuery
    });

    useEffect(() => {
        if (currentQuery) {
            handleClick(pathname, parseCurrentQuery(currentQuery))
        }
    }, [currentQuery]);

    useEffect(() => {
        let favProducts = getWithExpiry("trc-front-exported")
        if (favProducts){
            setFavoriteProducts(favProducts)
        }
    }, [])

    const [dataStructured, setDataStructured] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [shopType, setShopType] = useState(query && query.shopType ? query.shopType : '');
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [minDrop, setMinDrop] = useState("");
    const [maxDrop, setMaxDrop] = useState("");
    const [cushioningType, setCushioningType] = useState("");
    const [openExportProductsModal, setOpenExportProductsModal] = useState(false)

    const { t, i18n } = useTranslation();

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = event.selected * pageLimit % products.length;
        setItemOffset(newOffset);
        setCurrentQuery({ ...currentQuery, page: event.selected+1})
    };

    const resetFavoritesProducts = () => {
        localStorage.removeItem('trc-front-exported')
        setFavoriteProducts([])
        window.location.href = "/"
    }

    const fetchMerchandsData = () => {
        var lang = ""
        if(i18n.language == "be"){
          lang = "fr"
        } else {
          lang = i18n.language
        }
        let query = 'https://public-front-api.therunningcollective.fr/merchants?lang='+lang
    
        axios
            .get(query)
            .then((res) => {
              if (res.data.length > 0) {
                setAvailableRetailers(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
      };

      const fetchCategoriesData = (parentCategory) => {
        var lang = ""
        if(i18n.language == "be"){
          lang = "fr"
        } else {
          lang = i18n.language
        }
        let query = 'https://public-front-api.therunningcollective.fr/categories?headCategory='+parentCategory
    
        axios
            .get(query)
            .then((res) => {
              if (res.data.length > 0) {
                setAvailableCategories(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
      };

      const fetchBrandsData = (parentCategory) => {
        var lang = ""
        if(i18n.language == "be"){
          lang = "fr"
        } else {
          lang = i18n.language
        }
        let query = 'https://public-front-api.therunningcollective.fr/brands?headCategory=/products'+parentCategory
    
        axios
            .get(query)
            .then((res) => {
              if (res.data.length > 0) {
                setAvailableBrands(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
      };

      const fetchColorsData = (parentCategory) => {
        var lang = ""
        if(i18n.language == "be"){
          lang = "fr"
        } else {
          lang = i18n.language
        }
        let query = 'https://public-front-api.therunningcollective.fr/detailed-colors?headCategory=all'
    
        axios
            .get(query)
            .then((res) => {
              if (res.data.length > 0) {
                setAvailableColors(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
      };
    
      useEffect(() => {
        fetchBrandsData(parentCategory);
        fetchCategoriesData(parentCategory);
        fetchColorsData(parentCategory);
        fetchMerchandsData();
      }, [parentCategory]);

    useEffect(() => {
        if (query) {
            setGenderSortType(query && query.genders ? 'gender' : '');
            setGenderSortValue(query && query.genders ? query.genders : '');

            setCategorySortType(query && query.category ? 'category' : '');
            setCategorySortValue(query && query.category ? query.category : '');

            setColorSortType(query && query.colors ? 'color' : '');
            setColorSortValue(query && query.colors ? query.colors : '');

            setBrandSortType(query && query.brands ? 'brand' : '');
            setBrandSortValue(query && query.brands ? query.brands : '');

            setSizeSortType(query && query.sizes ? 'size' : '');
            setSizeSortValue(query && query.sizes ? query.sizes : '');

            setRetailerSortType(query && query.retailers ? 'retailer' : '');
            setRetailerSortValue(query && query.retailers ? query.retailers : '');

            setFiltersSortType(query && query.filters ? 'filters' : '');
            setFiltersSortValue(query && query.filters ? query.filters : '');

            //getPriceSortParams(0, 0)

            setShopType(query.shopType ? query.shopType : '')

            if(query.page && initialProducts){
                const newOffset = (query.page -1) * pageLimit % initialProducts.length;
                setItemOffset(newOffset)
                setCurrentPage(query.page -1)
            }
        }
    }, [query]);

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        } else {
            setSmallScreen(false)
        }
    }, [])

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        } else {
            setSmallScreen(true)
        }
    })

    useEffect(() => {
        if (isMobileOnly || isTablet){
            setPageLimit(16)
            setLayout('grid two-column')
        }
    }, [isMobileOnly, isTablet])

    useEffect(() => {
        if (products && products.length > 0) {
            let lowPrice = getLowPrice(products)
            let highPrice = getHighPrice(products)
            setSortedLowPrice(lowPrice)
            setSortedHighPrice(highPrice)
            setGenderSortValue('')
            setBrandSortValue('')
            setCategorySortValue('')
            setColorSortValue('')
            setRetailerSortValue('')

            ReactPixel.pageView();
            ReactPixel.fbq('track', 'PageView');
        }
    }, [products])

    const getLayout = (layout) => {
        setLayout(layout)
    }

    const getSortParams = (sortType, sortValue) => {
        setSortType(sortType);
        setSortValue(sortValue);
    }

    const getPriceSortParams = (priceSortType, priceSortValue) => {
        setPriceSortType(priceSortType);
        setPriceSortValue(priceSortValue);
    }

    const getWeighSortParams = (weighSortType, weighSortValue) => {
        setWeighSortType(weighSortType);
        setWeighSortValue(weighSortValue);
    }

    const getDropSortParams = (dropSortType, dropSortValue) => {
        setDropSortType(dropSortType);
        setDropSortValue(dropSortValue);
    }

    const getGenderSortParams = (genderSortType) => {
        setGenderSortType(genderSortType);
    }

    const getCategorySortParams = (categorySortType) => {
        setCategorySortType(categorySortType);
    }

    const getBrandSortParams = (brandSortType) => {
        setBrandSortType(brandSortType);
    }

    const getColorSortParams = (colorSortType) => {
        setColorSortType(colorSortType);
    }

    const getSizeSortParams = (sizeSortType) => {
        setSizeSortType(sizeSortType);
    }

    const getRetailerSortParams = (retailerSortType) => {
        setRetailerSortType(retailerSortType);
    }

    const getFilterSortParams = (sortType, sortValue) => {
        setFilterSortType(sortType);
        setFilterSortValue(sortValue);
    }

    const fetchInitialData = (parentCategory, pathname, currentQuery) => {
        let shopType = currentQuery.shopType
        let page = currentQuery.page
        let category = currentQuery.category
        let filters = currentQuery.filters
        let genders = currentQuery.genders
        let sizes = currentQuery.sizes
        let colors = currentQuery.colors
        let brands = currentQuery.brands
        let retailers = currentQuery.retailers
        let lowPrice = currentQuery.lowPrice
        let highPrice = currentQuery.highPrice
        let lowWeigh = currentQuery.lowWeigh
        let highWeigh = currentQuery.highWeigh
        let lowDrop = currentQuery.lowDrop
        let highDrop = currentQuery.highDrop
        let keywords = currentQuery.keywords
        let painType = currentQuery.pains
        let motorPreference = currentQuery.motor
        let shoeType = currentQuery.types
        let analysis = currentQuery.analysis

        if (page == "") {
            page = "1"
        }

        if (origin && origin == "be"){
            origin = "fr"
        }

        let query = 'https://public-front-api.therunningcollective.fr/products?lang='+origin+'&page=' + page
        if (shopType) {
            query = query + '&shopType=' + shopType
        }
        if (parentCategory !== "" && parentCategory !== "/") {
            query = query + '&headCategory=' + pathname
        }
        if (category) {
            query = query + '&subCategory=' + category
        }
        if (filters) {
            query = query + '&filters=' + filters
        }
        if (genders) {
            query = query + '&genders=' + genders
        }
        if (sizes) {
            query = query + '&sizes=' + sizes
        }
        if (colors) {
            query = query + '&colors=' + colors
        }
        if (brands) {
            query = query + '&brands=' + brands
        }
        if (retailers) {
            query = query + '&retailers=' + retailers
        }
        if (lowPrice) {
            query = query + '&lowPrice=' + lowPrice
        }
        if (highPrice) {
            query = query + '&highPrice=' + highPrice
        }
        if (lowWeigh) {
            query = query + '&lowWeigh=' + lowWeigh
        }
        if (highWeigh) {
            query = query + '&highWeigh=' + highWeigh
        }
        if (lowDrop) {
            query = query + '&lowDrop=' + lowDrop
        }
        if (highDrop) {
            query = query + '&highDrop=' + highDrop
        }
        if (shoeType) {
            query = query + '&shoeType=' + shoeType
        }
        if (painType) {
            query = query + '&painType=' + painType
        }
        if (motorPreference) {
            query = query + '&motorPreference=' + motorPreference
        }
        if (analysis) {
            query = query + '&analysis=' + analysis
        }

        if (keywords) {
            query = 'https://public-front-api.therunningcollective.fr/search-products?keywords='+keywords
            axios
            .get(query)
            .then((res) => {
                setInitialProducts(res.data ? res.data : []);
                setHasMore(false)
                setTimeout(function () {
                    const indexString = localStorage.getItem('trc-client-old-page-view-token-index');
                    let indexToken = JSON.parse(indexString);
                    const oldPositionString = localStorage.getItem('trc-client-old-page-view-token-position-' + indexToken);
                    const oldPositionToken = JSON.parse(oldPositionString);

                    if (oldPositionToken && window.location.pathname.includes("/products/")) {
                        window.scrollTo(0, Math.floor((oldPositionToken) - 100));
                        localStorage.setItem('trc-client-old-page-view-token-position-' + indexToken, JSON.stringify(oldPositionToken));
                    }
                }, 300);
                window.prerenderReady = true
            })
            .catch((err) => {
                setError(err);
                if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
                    window.location.href = "/logout"
                }
                window.prerenderReady = true
            })
            .finally(() => {
                setloading(false);
            });
        } else {
            axios
            .post(query, null)
            .then((res) => {
                setInitialProducts(res.data.products);
                setGenders(["Men", "Women", "Unisex"])
                setHasMore(res.data.lastEvaluatedKey ? true : false)
                setLastEvaluatedKey(res.data.lastEvaluatedKey)
                setSortedLowPrice(res.data.lowPrice)
                setSortedHighPrice(res.data.highPrice)
                setSortedLowWeigh(res.data.lowWeigh)
                setSortedHighWeigh(res.data.highWeigh)
                setSortedLowDrop(res.data.lowDrop)
                setSortedHighDrop(res.data.highDrop)
                setCountOfProducts(res.data.products);
                setMinDrop(res.data.minDrop);
                setMaxDrop(res.data.maxDrop);
                setCushioningType(res.data.cushioningType);
                setTimeout(function () {
                    const indexString = localStorage.getItem('trc-client-old-page-view-token-index');
                    let indexToken = JSON.parse(indexString);
                    const oldPositionString = localStorage.getItem('trc-client-old-page-view-token-position-' + indexToken);
                    const oldPositionToken = JSON.parse(oldPositionString);

                    if (oldPositionToken && window.location.pathname.includes("/products/")) {
                        window.scrollTo(0, Math.floor((oldPositionToken) - 100));
                        localStorage.setItem('trc-client-old-page-view-token-position-' + indexToken, JSON.stringify(oldPositionToken));
                    }
                }, 300);
                window.prerenderReady = true
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
                    window.location.href = "/logout"
                }
                window.prerenderReady = true
            })
            .finally(() => {
                setloading(false);
            });
        }        
    };

    const fetchMoreProductsData = (parentCategory, pathname, currentQuery, lastEvaluatedKey) => {
        let shopType = currentQuery.shopType
        let page = currentQuery.page
        let category = currentQuery.category
        let filters = currentQuery.filters
        let genders = currentQuery.genders
        let sizes = currentQuery.sizes
        let colors = currentQuery.colors
        let brands = currentQuery.brands
        let retailers = currentQuery.retailers
        let lowPrice = currentQuery.lowPrice
        let highPrice = currentQuery.highPrice
        let lowWeigh = currentQuery.lowWeigh
        let highWeigh = currentQuery.highWeigh
        let lowDrop = currentQuery.lowDrop
        let highDrop = currentQuery.highDrop
        let keywords = currentQuery.keywords
        let painType = currentQuery.pains
        let motorPreference = currentQuery.motor
        let shoeType = currentQuery.types
        let analysis = currentQuery.analysis

        if (page == "") {
            page = "1"
        }

        if (origin && origin == "be"){
            origin = "fr"
        }

        let query = 'https://public-front-api.therunningcollective.fr/products?lang='+origin+'&page=' + page
        if (shopType) {
            query = query + '&shopType=' + shopType
        }
        if (parentCategory !== "" && parentCategory !== "/") {
            query = query + '&headCategory=' + pathname
        }
        if (category) {
            query = query + '&subCategory=' + category
        }
        if (filters) {
            query = query + '&filters=' + filters
        }
        if (genders) {
            query = query + '&genders=' + genders
        }
        if (sizes) {
            query = query + '&sizes=' + sizes
        }
        if (colors) {
            query = query + '&colors=' + colors
        }
        if (brands) {
            query = query + '&brands=' + brands
        }
        if (retailers) {
            query = query + '&retailers=' + retailers
        }
        if (lowPrice) {
            query = query + '&lowPrice=' + lowPrice
        }
        if (highPrice) {
            query = query + '&highPrice=' + highPrice
        }
        if (lowWeigh) {
            query = query + '&lowWeigh=' + lowWeigh
        }
        if (highWeigh) {
            query = query + '&highWeigh=' + highWeigh
        }
        if (lowDrop) {
            query = query + '&lowDrop=' + lowDrop
        }
        if (highDrop) {
            query = query + '&highDrop=' + highDrop
        }
        if (shoeType) {
            query = query + '&shoeType=' + shoeType
        }
        if (painType) {
            query = query + '&painType=' + painType
        }
        if (motorPreference) {
            query = query + '&motorPreference=' + motorPreference
        }
        if (analysis) {
            query = query + '&analysis=' + analysis
        }

        if (keywords) {
            query = 'https://public-front-api.therunningcollective.fr/search-products?keywords='+keywords
            axios
            .get(query)
            .then((res) => {
                setInitialProducts(res.data ? res.data : []);
                setHasMore(false)
                setTimeout(function () {
                    const indexString = localStorage.getItem('trc-client-old-page-view-token-index');
                    let indexToken = JSON.parse(indexString);
                    const oldPositionString = localStorage.getItem('trc-client-old-page-view-token-position-' + indexToken);
                    const oldPositionToken = JSON.parse(oldPositionString);

                    if (oldPositionToken && window.location.pathname.includes("/products/")) {
                        window.scrollTo(0, Math.floor((oldPositionToken) - 100));
                        localStorage.setItem('trc-client-old-page-view-token-position-' + indexToken, JSON.stringify(oldPositionToken));
                    }
                }, 300);
                window.prerenderReady = true
            })
            .catch((err) => {
                setError(err);
                if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
                    window.location.href = "/logout"
                }
                window.prerenderReady = true
            })
            .finally(() => {
                setloading(false);
            });
        } else {
            let body = null
            if (lastEvaluatedKey) {
                body = JSON.stringify(lastEvaluatedKey)
            }

            axios
            .post(query, body)
            .then((res) => {
                setInitialProducts(res.data.products ? [...initialProducts, ...res.data.products] : initialProducts && initialProducts.length > 0 ? initialProducts : []);
                setGenders(["Men", "Women", "Unisex"])
                setHasMore(res.data.lastEvaluatedKey ? true : false)
                setLastEvaluatedKey(res.data.lastEvaluatedKey)
                setSortedLowPrice(res.data.lowPrice)
                setSortedHighPrice(res.data.highPrice)
                setSortedLowWeigh(res.data.lowWeigh)
                setSortedHighWeigh(res.data.highWeigh)
                setSortedLowDrop(res.data.lowDrop)
                setSortedHighDrop(res.data.highDrop)
                setCountOfProducts(res.data.products);
                setMinDrop(res.data.minDrop);
                setMaxDrop(res.data.maxDrop);
                setCushioningType(res.data.cushioningType);
                setTimeout(function () {
                    const indexString = localStorage.getItem('trc-client-old-page-view-token-index');
                    let indexToken = JSON.parse(indexString);
                    const oldPositionString = localStorage.getItem('trc-client-old-page-view-token-position-' + indexToken);
                    const oldPositionToken = JSON.parse(oldPositionString);

                    if (oldPositionToken && window.location.pathname.includes("/products/")) {
                        window.scrollTo(0, Math.floor((oldPositionToken) - 100));
                        localStorage.setItem('trc-client-old-page-view-token-position-' + indexToken, JSON.stringify(oldPositionToken));
                    }
                }, 300);
                window.prerenderReady = true
            })
            .catch((err) => {
                setError(err);
                if (err.response && err.response.data && err.response.data.message && err.response.data.message === "token is expired"){
                    window.location.href = "/logout"
                }
                window.prerenderReady = true
            })
            .finally(() => {
                setloading(false);
            });
        }        
    };

    useEffect(() => {
        GetUserData().then(
            data => apiConversionRequest(data.query, "PageView")
        )
    }, [])

    useEffect(() => {
        fetchInitialData(parentCategory, pathname, currentQuery)
        let breadscrumb = {
            "@type": "BreadcrumbList",
            "@id": "https://app.therunningcollective."+origin+"/products/"+parentCategory+"#breadscrumb",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Accueil",
                    "item": "https://app.therunningcollective."+origin+"/"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": t(parentCategory),
                },
            ]
        }
        let structuredGlobal = {
            "@context": "https://schema.org",
            "@graph": [breadscrumb]
        }
        setDataStructured(structuredGlobal)
    }, [parentCategory, pathname, subCategory, currentQuery]);


    useEffect(() => {
        if(!query.page){
            setCurrentQuery({ page: 1})
        }
    }, [subCategory, query])

    const fetchMoreData = (parentCategory, pathname, currentQuery, lastEvaluatedKey) => {
        fetchMoreProductsData(parentCategory, pathname, currentQuery, lastEvaluatedKey)
    };

    useEffect(() => {
        let newInitialProducts = []
        let favProducts = getWithExpiry("trc-front-exported")
        if (favProducts && favProducts.length > 0){
            let items = []
            favProducts.map(p =>{
                p.isFavoriteItem = true
                items.push(p)
            })
            newInitialProducts = items.concat(initialProducts)
            setloading(false)
            setHasMore(false)
            setGenders(["Men", "Women", "Unisex"])
            setFavoriteProducts(favProducts)
        }
        if (!loading && newInitialProducts && newInitialProducts.length > 0) {
            let sortedProducts = getShowProducts(newInitialProducts)
            //sortedProducts = getSortedProducts(sortedProducts, sortType, sortValue, categorySortType, categorySortValue, brandSortType, brandSortValue, genderSortType, genderSortValue, colorSortType, colorSortValue, sizeSortType, sizeSortValue, priceSortType, priceSortValue, retailerSortType, retailerSortValue, weighSortType, weighSortValue, dropSortType, dropSortValue, filtersSortType, filtersSortValue);
            let filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue, categorySortType, categorySortValue, brandSortType, brandSortValue, genderSortType, genderSortValue, colorSortType, colorSortValue, sizeSortType, sizeSortValue, priceSortType, priceSortValue, retailerSortType, retailerSortValue, weighSortType, weighSortValue, dropSortType, dropSortValue, filtersSortType, filtersSortValue);
            filterSortedProducts =  removeDuplicateProduct(filterSortedProducts)

            setSortedProducts(filterSortedProducts);
            const endOffset = itemOffset + pageLimit;
            setCurrentData(sortedProducts.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(countOfProducts / pageLimit));
        } else if (initialProducts == null) {
            setSortedProducts([]);
        }
    }, [initialProducts, shopType, itemOffset, pageLimit, sortType, sortValue, categorySortType, categorySortValue, brandSortType, brandSortValue, genderSortType, genderSortValue, filterSortType, filterSortValue, colorSortType, colorSortValue, sizeSortType, sizeSortValue, priceSortType, priceSortValue, loading, sortedHighPrice, sortedLowPrice, retailerSortType, retailerSortValue, weighSortType, weighSortValue, dropSortType, dropSortValue, filtersSortType, filtersSortValue]);

    return (
        <Fragment>
            <MetaTags>
                <Helmet>
                    <title>Mes favoris</title>
                    <meta name="description"
                          content={"The Running Collective | Mes favoris"} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="The Running Collective | Mes Favoris" />
                    <meta property="og:type" content="article" />
                    <meta property="og:local" content={i18n.language == "en" ? "gb_GB" : i18n.language + "_"+ i18n.language.toUpperCase()} />
                    <meta property="og:description"
                          content={"The Running Collective, " + t('metatitle')} />
                    <meta property="og:image" content="https://img.therunningcollective.fr/head-03.jpg" />
                    {
                        dataStructured !== null ?
                            <script type="application/ld+json">
                                {JSON.stringify(dataStructured)}
                            </script>
                            :
                            null
                    }
                </Helmet>
            </MetaTags>

            <BreadcrumbsItem to={process.env.PUBLIC_URL + '/'}>{t('home')}</BreadcrumbsItem>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + '/favorites'}>Mes favoris</BreadcrumbsItem>
            
            <LayoutOne
                location={location}
                favoriteProducts={favoriteProducts}
                setFavoriteProducts={setFavoriteProducts}
                headerContainerClass="container-fluid"
                headerPaddingClass="header-padding-2"
                headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb />
                <div style={{display: 'none'}}>
                    <h1>The Running Collective | Mes favoris</h1>
                    <h2>{t('h2home')}</h2>
                </div>

                {
                    isMenuOpen ?
                        <FilterMenu
                            location={location}
                            currentQuery={currentQuery}
                            setCurrentQuery={setCurrentQuery}
                            sortedHighPrice={sortedHighPrice}
                            sortedLowPrice={sortedLowPrice}
                            products={products}
                            uniqueGenders={genders}
                            uniqueCategories={availableCategories}
                            uniqueSizes={getAvailablesSizes(parentCategory)}
                            uniqueBrands={availableBrands}
                            uniqueColors={availableColors}
                            uniqueRetailers={availableRetailers}
                            getSortParams={getSortParams}
                            getCategorySortParams={getCategorySortParams}
                            getBrandSortParams={getBrandSortParams}
                            getGenderSortParams={getGenderSortParams}
                            getColorSortParams={getColorSortParams}
                            getSizeSortParams={getSizeSortParams}
                            getRetailerSortParams={getRetailerSortParams}
                            getPriceSortParams={getPriceSortParams}
                            setIsOpen={setIsMenuOpen}
                            pathname={pathname}
                        />
                        : null
                }

                {loading ? <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" role="status" >
                                <span className="visually-hidden"></span>
                            </Spinner>
                            </div>
                    : (sortedProducts && sortedProducts.length > 0) ?
                    <div className={isMobileOnly ? "shop-area pb-100" : "shop-area pt-10 pb-100"}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-3 order-1 order-lg-1">
                                </div>
                                <div className="col-lg-9 order-2 order-lg-2">
                                {
                                    isAmbassador  && !isMobileOnly ? 
                                    <Row style={{paddingTop : isMobileOnly ? "20px" : "10px", paddingBottom: "10px"}}>
                                        <Col xs={isMobileOnly ? 6 : 3} className={"text-left"} style={{cursor: "pointer"}} onClick={() => setOpenExportProductsModal(true)}>
                                            {
                                                favoriteProducts && favoriteProducts.length > 0 ?
                                                    <span style={{padding : "10px 15px", color: "white", borderRadius: "5px", backgroundColor: "black"}}>Exporter la sélection &nbsp;&nbsp;<IoCloudDownloadOutline size={"1.3rem"}/></span>
                                                : null
                                            }
                                        </Col>
                                        <Col xs={isMobileOnly ? 6 : 3} className={"text-left"} style={{cursor: "pointer"}} onClick={() => resetFavoritesProducts()}>
                                            {
                                                favoriteProducts && favoriteProducts.length > 0 ?
                                                    <span style={{padding : "10px 15px", color: "white", borderRadius: "5px", backgroundColor: "gray"}}>Réinitialiser {isMobileOnly ? "" : "la sélection"} &nbsp;&nbsp;<MdLockReset size={"1.3rem"}/></span>
                                                : null
                                            }
                                        </Col>
                                    </Row>
                                    :  isAmbassador  && isMobileOnly ? 
                                        <Row style={{paddingTop : isMobileOnly ? "20px" : "10px", paddingBottom: "10px"}}>
                                            <Col xs={isMobileOnly ? 6 : 3} className={"text-left"} style={{cursor: "pointer"}} onClick={() => setOpenExportProductsModal(true)}>
                                                {
                                                    favoriteProducts && favoriteProducts.length > 0 ?
                                                        <div style={{padding : "10px 15px", color: "white", borderRadius: "5px", backgroundColor: "black"}}>Exporter la sélection &nbsp;&nbsp;<IoCloudDownloadOutline size={"1.3rem"}/></div>
                                                    : null
                                                }
                                            </Col>
                                            <Col xs={isMobileOnly ? 6 : 3} className={"text-left"} style={{cursor: "pointer"}} onClick={() => resetFavoritesProducts()}>
                                                {
                                                    favoriteProducts && favoriteProducts.length > 0 ?
                                                        <div style={{padding : "10px 15px", color: "white", borderRadius: "5px", backgroundColor: "gray"}}>Réinitialiser {isMobileOnly ? "" : "la sélection"} &nbsp;&nbsp;<MdLockReset size={"1.3rem"}/></div>
                                                    : null
                                                }
                                            </Col>
                                        </Row> : null
                                    }
                                    
                                    {/* shop topbar default */}
                                    {
                                        sortedProducts && sortedProducts.length > 0 ?
                                            <InfiniteScroll
                                                scrollThreshold="50%"
                                                style={{overflow: "inherit"}}
                                                dataLength={sortedProducts.length}
                                                next={() => fetchMoreData(parentCategory, pathname, currentQuery, lastEvaluatedKey)}
                                                hasMore={hasMore}
                                                loader={<div style={{textAlign: 'center'}}>
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden"></span>
                                                    </Spinner>
                                                </div>}
                                            >
                                                <ShopProducts layout={layout} products={sortedProducts} setFavoriteProducts={setFavoriteProducts} favoriteProducts={favoriteProducts}/>
                                            </InfiniteScroll>
                                            : <div
                                                style={{textAlign: 'center', marginTop: '50px', marginBottom: '50px'}}>
                                                <span>Aucun produit disponible :(</span>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div style={{textAlign: 'center', marginTop: '50px', marginBottom: '50px'}}>
                            <span>Aucun produit disponible :(</span>
                        </div>  }
                {error ? <span>Erreur durant le chargement des données</span> : null }
                {
                    openExportProductsModal && isAmbassador ?
                        <ExportProductsModal  currentQuery={currentQuery} open={openExportProductsModal} setOpen={setOpenExportProductsModal} products={favoriteProducts}/>
                    : null
                }
            </LayoutOne>
        </Fragment>
    )
}

export function parseCurrentQuery(currentQuery) {
    let query = ""
    if ((currentQuery && (currentQuery.genders ||
        currentQuery.category || currentQuery.sizes ||
        currentQuery.colors || currentQuery.brands || currentQuery.page || currentQuery.retailers || currentQuery.product
        || currentQuery.filters || currentQuery.highPrice || currentQuery.lowPrice
        || currentQuery.highWeigh || currentQuery.lowWeigh
        || currentQuery.highDrop || currentQuery.lowDrop || currentQuery.type
        || currentQuery.month || currentQuery.year || currentQuery.destination
        || currentQuery.distance || currentQuery.deniv || currentQuery.stars || currentQuery.tag || currentQuery.keywords || currentQuery.analysis ||
        currentQuery.forecastID || currentQuery.pseudo || currentQuery.userID || currentQuery.motor || currentQuery.types || currentQuery.pains))) {
        query = "?"
        if (currentQuery.genders) {
            query = query + "genders=" + currentQuery.genders + "&"
        }
        if (currentQuery.category) {
            query = query + "category=" + currentQuery.category + "&"
        }
        if (currentQuery.sizes) {
            query = query + "sizes=" + currentQuery.sizes + "&"
        }
        if (currentQuery.brands) {
            query = query + "brands=" + currentQuery.brands + "&"
        }
        if (currentQuery.colors) {
            query = query + "colors=" + currentQuery.colors + "&"
        }
        if (currentQuery.retailers) {
            query = query + "retailers=" + currentQuery.retailers + "&"
        }
        if (currentQuery.shopType) {
            query = query + "shopType=" + currentQuery.shopType + "&"
        }
        if (currentQuery.product) {
            query = query + "product=" + currentQuery.product + "&"
        }
        if (currentQuery.filters) {
            query = query + "filters=" + currentQuery.filters + "&"
        }
        if (currentQuery.highPrice) {
            query = query + "highPrice=" + currentQuery.highPrice + "&"
        }
        if (currentQuery.lowPrice) {
            query = query + "lowPrice=" + currentQuery.lowPrice + "&"
        }
        if (currentQuery.highWeigh) {
            query = query + "highWeigh=" + currentQuery.highWeigh + "&"
        }
        if (currentQuery.lowWeigh) {
            query = query + "lowWeigh=" + currentQuery.lowWeigh + "&"
        }
        if (currentQuery.highDrop) {
            query = query + "highDrop=" + currentQuery.highDrop + "&"
        }
        if (currentQuery.lowDrop) {
            query = query + "lowDrop=" + currentQuery.lowDrop + "&"
        }
        if (currentQuery.page) {
            query = query + "page=" + currentQuery.page + "&"
        }
        if (currentQuery.type) {
            query = query + "type=" + currentQuery.type + "&"
        }
        if (currentQuery.startDate) {
            query = query + "startDate=" + currentQuery.startDate + "&"
        }
        if (currentQuery.endDate) {
            query = query + "endDate=" + currentQuery.endDate + "&"
        }
        if (currentQuery.destination) {
            query = query + "destination=" + currentQuery.destination + "&"
        }
        if (currentQuery.distance) {
            query = query + "distance=" + currentQuery.distance + "&"
        }
        if (currentQuery.deniv) {
            query = query + "deniv=" + currentQuery.deniv + "&"
        }
        if (currentQuery.stars) {
            query = query + "stars=" + currentQuery.stars + "&"
        }
        if (currentQuery.tag) {
            query = query + "tag=" + currentQuery.tag + "&"
        }
        if (currentQuery.keywords) {
            query = query + "keywords=" + currentQuery.keywords + "&"
        }
        if (currentQuery.forecastID){
            query = query + "&forecastID=" + currentQuery.forecastID + "&"
        }
        if (currentQuery.userID){
            query = query + "&userID=" + currentQuery.userID + "&"
        }
        if (currentQuery.pseudo){
            query = query + "&pseudo=" + currentQuery.pseudo + "&"
        }
        if (currentQuery.motor){
            query = query + "&motor=" + currentQuery.motor + "&"
        }
        if (currentQuery.pains){
            query = query + "&pains=" + currentQuery.pains + "&"
        }
        if (currentQuery.types){
            query = query + "&types=" + currentQuery.types + "&"
        }
        if (currentQuery.analysis){
            query = query + "&analysis=" + currentQuery.analysis + "&"
        }

        query = query.slice(0, -1);
    }

    return query
}

function removeDuplicateProduct(products){
    let newItems = []
    products.map(product => {
      let notExists = true
      let shortURL = product.shortURL
      if (product.shortURL.split("?") && product.shortURL.split("?").length > 1){
        shortURL = product.shortURL.split("?")[0]
      }
      if(newItems.length > 0){
        newItems.map(newItem => {
            let newShortURL = newItem.shortURL
            if (newItem.shortURL.split("?") && newItem.shortURL.split("?").length > 1){
                newShortURL = newItem.shortURL.split("?")[0]
            }
            if (product.headCategory == newItem.headCategory && newShortURL == shortURL){
                notExists = false
              }
          })
        if(notExists){
            newItems.push(product)
        }
      } else {
        newItems.push(product)
      }
    })
    return newItems
}

ShopGridFullWidthFavorite.propTypes = {
  location: PropTypes.object,
}

export default multilanguage(ShopGridFullWidthFavorite);
