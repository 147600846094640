import PropTypes from "prop-types";
import React from "react";
import {isMobileOnly} from "react-device-detect";

const SectionTitleFive = ({ titleText, isSide }) => {
  return (
    <div className="section-title-4" style={{paddingLeft : isMobileOnly || !isSide ? "15px": "0px"}}>
      <h3 className="bg-gray-5">{titleText}</h3>
    </div>
  );
};

SectionTitleFive.propTypes = {
  sectionTitle: PropTypes.string
};

export default SectionTitleFive;
