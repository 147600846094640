import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {sleep} from "../../helpers/tools";
import ReactGA from "react-ga4";
import {Col, Row, Spinner} from "react-bootstrap";

import Slider  from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from "axios";
import {getClientTokenData} from "../../App";

function AppointmentValidationModal(props) {

  console.log(props)
  const handleClose = () => {
    let query = 'https://public-front-api.therunningcollective.fr/physical-shop-book-appointment'
      axios.post(query, JSON.stringify(
          {
              appointmentDate: props.selectedDay,
              physicalShopID: props.physicalShopID, 
              physicalShopName: props.physicalShopName,
              physicalShopImg: props.physicalShopImg
          }
      ), {
          headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
          }
          ,}
      )
      .then((res) => {
          console.log(res)
          sleep(2000)
          window.location.href = "/appointments"
      }).catch(function (error) {
          if (error.response) {
              // Request made and server responded
              //setLoadingButton(false)
              //setError(error.response.data.msg)
          }
      });
  }

  return (
    <Fragment>
      <Modal
        size={"sm"}
        show={props.show}
        onHide={props.onHide}
        className="product-quickview-modal-wrapper"
      >
        <Modal.Header closeButton><h3>Validation du Rendez-Vous</h3></Modal.Header>
        <div className="modal-body">

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <p>Confirmez-vous votre rendez-vous à <b>{props.physicalShopName}</b> le <b>{props.selectedDay}</b> ?</p>
            </div>
            <br/>
            <div>
              <div className="pro-details-size col-md-6 col-sm-6 col-xs-12">
                
              </div>
              <div className="container">
                <Row>
                  <Col xs={6}>
                    <button
                        onClick={() => props.onHide()}
                        style={{
                            backgroundColor: "black",
                            color: "white",
                            borderRadius:'10px',
                            width: "100%",
                            border: "3px solid #4fa0a6",
                            paddingTop: "4px",
                            textTransform: "uppercase"
                        }}>
                        Annuler
                    </button>
                  </Col>
                  <Col xs={6}>
                    <button
                        rel="noopener noreferrer"
                        onClick={() => handleClose()}
                        style={{
                          backgroundColor: "#4fa0a6",
                          color: "white",
                          borderRadius:'10px',
                          width: "100%",
                          border: "3px solid black",
                          paddingTop: "4px",
                          textTransform: "uppercase"
                      }}>
                      Valider
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        </div>
      </Modal>
    </Fragment>
  );
}



AppointmentValidationModal.propTypes = {
  unavailableSizes: PropTypes.array,
  onHide: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    cartitems: state.cartData
  };
};

export default connect(mapStateToProps)(AppointmentValidationModal);

//creating function to load ip address from the API
export const GetEmailUserData = async (email) => {
  var url = "https://pro.ip-api.com/json/?key=Y1hZznfd2inZrpm&fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query"
  const resData = await axios.get(url)
  if (resData.data) {
    resData.data.location = window.location.href
    resData.data.email = email
  }
  var query = "https://api.therunningcollective.fr/api/public/new-data"
  axios.post(query, JSON.stringify(resData.data))
}