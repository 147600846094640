import React, {useEffect, useState} from "react";
import InputRange from 'react-input-range';
import "../../src/assets/css/multirange-slider.scss";
import 'react-input-range/lib/css/index.css';
import {isMobileOnly} from "react-device-detect";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const ShopMultiRangeSlider = ({ sortedHighPrice, sortedLowPrice, currentQuery, setCurrentQuery }) => {
    const [minValue, set_minValue] = useState(0);
    const [maxValue, set_maxValue] = useState(1);
    const [selectedPriceValue, setSelectedPriceValue] = useState({min: currentQuery.lowPrice ? currentQuery.lowPrice : sortedLowPrice, max: currentQuery.highPrice ? currentQuery.highPrice : sortedHighPrice});
    const [hide, setHide] = useState(false)
    const [display, setDisplay] = useState('none')
    const [isSmallScreen, setSmallScreen] = useState(false);
    const [selectedPriceFinalValue, setSelectedPriceFinalValue] = useState({min: currentQuery.lowPrice ? currentQuery.lowPrice : sortedLowPrice, max: currentQuery.highPrice ? currentQuery.highPrice : sortedHighPrice});
    const { t } = useTranslation()

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        }
    }, [])

    useEffect(() => {
        set_maxValue(sortedHighPrice)
        set_minValue(sortedLowPrice)
        setSelectedPriceValue({min: currentQuery.lowPrice ? currentQuery.lowPrice : sortedLowPrice, max: currentQuery.highPrice ? currentQuery.highPrice : sortedHighPrice})
    }, [sortedHighPrice, sortedLowPrice, currentQuery])

    useEffect(() => {
        setCurrentQuery({ ...currentQuery, lowPrice: selectedPriceFinalValue.min, highPrice: selectedPriceFinalValue.max});
    }, [selectedPriceFinalValue])

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(true)
        }
    })

    useEffect(() => {
        if (hide) {
            setDisplay('none')
        } else {
            setDisplay('block')
        }
    }, [hide])

    useEffect(() => {
        if (isMobileOnly || isSmallScreen){
            setHide(false)
        }
    }, [isSmallScreen])

    return (
        <div className="sidebar-widget mt-10 mb-20">
            <div>
                <h4 className="pro-sidebar-title">{t('Prix')} &nbsp;
                </h4>
                <div className="sidebar-widget-tag-gender mt-20" style={{display: display}}>
                    { sortedHighPrice > 1 && maxValue > 1 && minValue >= 0 ?
                        <InputRange
                            draggableTrack
                            maxValue={sortedHighPrice}
                            minValue={sortedLowPrice}
                            step={10}
                            formatLabel={value => `${value} €`}
                            onChange={value => setSelectedPriceValue(value)}
                            onChangeComplete={value => {
                                setSelectedPriceFinalValue(value)
                                setSelectedPriceValue(value)}
                            }
                            value={selectedPriceValue}/>
                        :
                        null
                    }

                </div>
            </div>
        </div>
    );
};

ShopMultiRangeSlider.propTypes = {
    sortedHighPrice: PropTypes.any,
    sortedLowPrice: PropTypes.any,
    getSortParams: PropTypes.func
};

export default ShopMultiRangeSlider;