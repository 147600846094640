import React from 'react';
import {useTranslation} from "react-i18next";
const TrustBox = () => {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    const { i18n } = useTranslation();

    React.useEffect(() => {
// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
// When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div
            ref={ref} // We need a reference to this element to load the TrustBox in the effect.
            className="trustpilot-widget" // Renamed this to className.
            // [ long list of data attributes...]
            data-locale={i18n.language + "-" + i18n.language.toUpperCase()} data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="61e58621f6cf9710d89b4341" data-style-height="150px" data-style-width="60%" data-theme="light"
        >
            <a href="https://fr.trustpilot.com/review/therunningcollective.fr" target="_blank" rel="noopener">Trustpilot</a>
        </div>
    );
};
export default TrustBox;