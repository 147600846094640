import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from "prop-types";
import { isMobileOnly } from 'react-device-detect';

const PimsterWidget = ({productName, moduleID, isProductPage}) => {
    
    const [display, setDisplay] = useState(productName == "page-daccueil" ?  "round" : "square")
    const [displayMarginLeft, setDisplayMarginLeft] = useState("")
    const [displayHeight, setDisplayHeight] = useState(productName == "page-daccueil" ? "140px" : "253px")
    const [displayAnimations, setAnimations] = useState('["onHover"]')

    useEffect(() => {
        if (isMobileOnly){
            setDisplay("round")
            setDisplayHeight("135px")
            setDisplayMarginLeft(isProductPage ? "140px" : "")
        } else {
            setDisplayMarginLeft(isProductPage ? "140px" : "")
            setAnimations(isProductPage ? '["autoplay"]' : '["onHover"]')
        }
    }, [isProductPage])
    

    const htmlContent = '<pimster-gallery-widget \
                            company="therunningcollective" \
                            product='+productName+' \
                            moduleId='+moduleID+' \
                            display=' + display + ' \
                            borderColor="black" \
                            cookieConsent="accepted" \
                            animations='+displayAnimations+'> \
                        </pimster-gallery-widget>';

  return <div style={{textAlign: isProductPage ? "left" : "center", height: displayHeight, marginLeft: displayMarginLeft}}>{ReactHtmlParser(htmlContent)}</div>;
};

PimsterWidget.propTypes = {
    productName: PropTypes.any,
    moduleID: PropTypes.any,
    isProductPage: PropTypes.any,
};

export default PimsterWidget;