import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import SnackbarCredits from "../Snackbar-credits";
import {useEffect} from "react";
import axios from "axios";
import {sleep} from "../../helpers/tools";
import Snackbar from "@mui/material/Snackbar";
import {useState} from "react";
import moment from "moment";
import TextField from '@mui/material/TextField';
import ForecastLeagueImageModal from './ForecastLeagueImageModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function JoinForecastLeagueModal({open, setOpen, forecastLeague}) {
    const handleClose = () => {
        setOpen(false)
    }

    const fetchJoinExistingLeague = (forecastLeague) => {
        let query = 'https://public-front-api.therunningcollective.fr/forecast-league'
        axios.post(query, {
            forecastID: forecastLeague.forecastID,
            password: forecastLeague.password,
        },
            {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,})
            .then((res) => {
                localStorage.setItem('trc-client-league-id-'+forecastLeague.forecastID, JSON.stringify(res.data));
                setOpen(false);
                setError(null);
                window.location.assign(process.env.PUBLIC_URL + "/forecast-leagues/"+forecastLeague.forecastID)
            })
            .catch((err) => {
                //window.location.href = "/404-not-found"
                console.log(err.response.data.message);
                if(err.response.data.message == "error while joining forecast league: league is already joined"){
                    setError("Vous avez déja rejoint la ligue.")
                } else {
                    setError("Une erreur s'est produite, veuillez réessayer.")
                }
            })
    };

    const [isGameOK, setIsGameOK] = useState(false)
    const [pseudo, setPseudo] = useState(null)
    const [error, setError] = useState(null)

    const handleCloseSnack = () => {
        setIsGameOK(false);
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical : 'top', horizontal : 'center' }}
                open={isGameOK}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                message={"Vous avez bien rejoint la ligue !"}
                key={'top' + 'center'}
                style={{marginTop: "50px"}}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:"center", lineHeight: "24px"}}>
                        Voulez-vous rejoindre la ligue {forecastLeague.name} ?
                    </Typography>
                    <div style={{marginTop: "25px"}}>
                        <div className="container-fluid" style={{marginTop: "20px", textAlign: "center"}}>                                     
                        <img src={
                            forecastLeague.imageURL ? forecastLeague.imageURL : 
                            "https://app-trc-images.s3.eu-west-3.amazonaws.com/point-interrogation-vector-doodle-icone-logo-support-technique-aide_499739-885.avif"} 
                            style={{
                                position: "relative",
                                width: "70px", 
                                height: "70px", 
                                borderRadius: "50%",
                                border: "1px solid lightgray"
                            }}
                        />
                        </div>
                    </div>
                    {
                        error ?
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{color : "red", fontSize:"14px", textAlign:"center"}}>
                            {error}
                        </Typography>
                     : null
                    }
                    <Row style={{marginTop: "30px", textAlign:"center"}}>
                        <Col>
                            <button
                                onClick={() => handleClose()}
                                style={{
                                    backgroundColor: "rgb(203, 200, 200)",
                                    color: "black",
                                    borderRadius:'5px',
                                    padding: "5px 40px",
                                    border: "none",
                                    fontSize: "15px",
                                    fontWeight: 600
                                }}>
                                Annuler
                            </button>
                        </Col>
                        <Col>
                            <button
                                onClick={() => fetchJoinExistingLeague(forecastLeague)}
                                style={{
                                    backgroundColor: "black",
                                    color: "white",
                                    borderRadius:'5px',
                                    padding: "5px 40px",
                                    border: "none",
                                    fontSize: "15px",
                                    fontWeight: 600
                                }}>
                                Valider
                            </button>
                        </Col>

                        {
                            error ?
                            <div style={{marginTop: "10px"}}>
                                <span style={{color: "red"}}>{error}</span>
                            </div>
                            : null
                        }
                    </Row>

                </Box>
            </Modal>
        </div>
    );
}

JoinForecastLeagueModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    forecast: PropTypes.any,
};

export default JoinForecastLeagueModal;