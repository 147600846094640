import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import PrivateSalesSingleTwo from "../../components/product/PrivateSalesSingleTwo";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import axios from "axios";
import CurrentVoucherSingleTwo from "../../components/product/CurrentVoucherSingleTwo";

const CurrentVouchersGridTwo = ({
  type,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItems,
  wishlistItems,
  compareItems,
  sliderClassName,
  spaceBottomClass,
  colorClass,
  titlePriceClass,
  currentVouchers,
  isSide
}) => {

  const [error, setError] = useState('');
  const [loading, setloading] = useState(false);

  return (
    <Fragment>
      {!loading ? currentVouchers && currentVouchers.length > 0 ? currentVouchers.map((currentVoucher, index) => {
        if(currentVoucher.vouchers) {
          return (
              <CurrentVoucherSingleTwo
                  sliderClassName={sliderClassName}
                  spaceBottomClass={spaceBottomClass}
                  colorClass={colorClass}
                  currentVoucher={currentVoucher.vouchers}
                  typeOfProducts={currentVoucher.typeOfProducts}
                  merchantInfo={currentVoucher.merchant}
                  currency={currency}
                  addToCart={addToCart}
                  addToWishlist={addToWishlist}
                  addToCompare={addToCompare}
                  isSide={isSide}
                  index={index}
                
                  key={currentVoucher.id}
                  titlePriceClass={titlePriceClass}
              />
          );
        }
      }) : <span>Aucun produit disponible</span> : <span>Chargement en cours</span> }
      {error ? <span>Erreur durant le chargement des données</span> : null }
    </Fragment>
  );
};

CurrentVouchersGridTwo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
  titlePriceClass: PropTypes.string,
  wishlistItems: PropTypes.array,
  shopType: PropTypes.string,
  origin: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {

  return {
    type: ownProps.type,
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentVouchersGridTwo);
