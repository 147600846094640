import PropTypes from "prop-types";
import React, {forwardRef, Fragment, useEffect, useState} from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import axios from "axios";
import {Col, Row, Spinner} from "react-bootstrap";
import {Divider} from "@mui/material";
import {
    AiFillWarning,
    AiFillCheckCircle
} from "react-icons/all";
import {
    useHistory,
  } from 'react-router-dom';
import {getClientTokenData} from "../../App";
import TokenSignInModal from "../../components/product/TokenSignInModal";
import {FaCircle} from "react-icons/fa";
import {sleep} from "../../helpers/tools";
import Countdown from "react-countdown";
import {Capacitor} from "@capacitor/core";
import {openMap} from "./PhysicalShop";
import { BrowserView, isMobileOnly, MobileOnlyView } from "react-device-detect";

const PhysicalShopAppointment = ({location}) => {
    const {pathname} = location;
    const history = useHistory();

    const reference = ""
    const price = ""
    const selectedSize = ""

    const physicalShopAppointmentID = location.pathname.split("/physical-shop-appointment/").pop()

    const [product, setProduct] = useState(null)
    const [physicalShop, setPhysicalShop] = useState(null)
    const [physicalShopAppointment, setPhysicalShopAppointment] = useState(null)
    const [declination, setDeclination] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)
    const [openModalSignIN, setOpenModalSignIN] = useState(false)
    const [token, setToken] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        let token = getClientTokenData()
        setToken(token)
      }, [])

    const rendererSoon = ({ days, hours, minutes, seconds, completed }) => {
       return <span style={{color: "red", fontWeight: 500, fontSize: "13px"}}>Expire dans {days}j {hours}h {minutes}mn</span>;
    };

    const getPhysicalShopAppointment = (id) => {
        let query = 'https://public-front-api.therunningcollective.fr/a-physical-shop-booked?id=' + id
        axios.post(query, null, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,}
        )
            .then((res) => {
                setPhysicalShopAppointment(res.data) 
                setLoading(false)             
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getPhysicalShop = (id) => {
        let query = 'https://public-front-api.therunningcollective.fr/physical-shop?id=' + id
        axios
            .get(query)
            .then((res) => {
                setPhysicalShop(res.data)              
            })
            .catch((err) => {
                console.log(err);
            })
    }


    useEffect(() => {
        getPhysicalShopAppointment(physicalShopAppointmentID);
    }, [physicalShopAppointmentID])

    useEffect(() => {
        if(physicalShopAppointment){
            getPhysicalShop(physicalShopAppointment.physicalShopID)
        }
    }, [physicalShopAppointment])

    return (
        <Fragment>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
                Rendez-vous {physicalShop && physicalShop.name} - {physicalShopAppointment ? new Date(physicalShopAppointment.untilAvailableDate).toLocaleDateString() : null} à {physicalShopAppointment ? new Date(physicalShopAppointment.untilAvailableDate).getHours() : null }h{physicalShopAppointment ? new Date(physicalShopAppointment.untilAvailableDate).getMinutes() != 0 ? new Date(physicalShopAppointment.untilAvailableDate).getMinutes() : "00" : null}
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible"
                       headerContainerClass="container-fluid"
                       headerPaddingClass="header-padding-2"
                       location={location}>
                {/* breadcrumb */}
                <Breadcrumb location={location}/>

                {
                        loading ?
                            <div style={{textAlign: 'center', marginTop: "80px"}}>
                                <Spinner animation="border" role="status" >
                                    <span className="visually-hidden"></span>
                                </Spinner>
                            </div>
                        : 
                        <div className={"container"} style={{paddingTop: Capacitor.getPlatform() === 'ios' ? "60px" : "20px"}}>
                    <MobileOnlyView>
                        <Row>
                            <Col xs={4}>
                                {
                                    physicalShopAppointment && physicalShopAppointment.productImg ?
                                        <img style={{maxWidth:"120px"}} src={physicalShopAppointment.productImg}/>
                                    : 
                                    <div style={{minHeight: "120px"}}>
                                        <img style={{maxWidth:"120px", position: "absolute", zIndex: "10"}} src={"https://img.therunningcollective.fr/shoes-background-1-jpeg-jpg-jpg"}/>
                                        <img style={{maxWidth:"50px", position: "relative", zIndex: "20", marginLeft: "35px", marginTop: "35px", borderRadius: "10px"}} src={physicalShopAppointment.physicalShopImg != "" ? physicalShopAppointment.physicalShopImg : "https://img.therunningcollective.fr/shoes-background-1-jpeg-jpg-jpg"}/>                                                       
                                    </div>
                                }
                            </Col>
                            <Col xs={8}>
                            {
                                physicalShopAppointment.productName != "" && physicalShopAppointment.productReference != "" ?
                                <>
                                    <div>{physicalShopAppointment && physicalShopAppointment.productName}</div>
                                    <div>Référence : {physicalShopAppointment && physicalShopAppointment.productReference}</div>
                                    <div>Prix : {physicalShopAppointment && physicalShopAppointment.price}€</div>
                                    <div>Taille : {physicalShopAppointment && physicalShopAppointment.selectedSize}</div>  
                                    <div><AiFillWarning size={22} style={{color: "red"}}/> <Countdown date={physicalShopAppointment && physicalShopAppointment.untilAvailableDate} renderer={rendererSoon}/></div>                        
                                </>
                                : 
                                <div>
                                    <div>
                                        Rendez-vous en magasin
                                    </div>
                                    <div>
                                            {new Date(physicalShopAppointment.untilAvailableDate).toLocaleDateString()} à {new Date(physicalShopAppointment.untilAvailableDate).getHours()}h{new Date(physicalShopAppointment.untilAvailableDate).getMinutes() != 0 ? new Date(physicalShopAppointment.untilAvailableDate).getMinutes() : "00"}
                                    </div>
                                </div>
                            }
                            </Col>
                        </Row>
                    </MobileOnlyView>
                    <BrowserView>
                        <>
                            <div style={{padding: "0", height: "230px", overflow: "hidden", position: "relative"}}>
                                <div>
                                    {
                                        physicalShop && physicalShop.logo ?
                                        <img src={physicalShop.logo} style={{
                                            width:"100%",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)"
                                        }}/>
                                        : null
                                    }
                                </div>
                            </div>
                            <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                            <div>
                                <h4>
                                    Rendez-vous en magasin
                                </h4>
                                <div>
                                        {new Date(physicalShopAppointment.untilAvailableDate).toLocaleDateString()} à {new Date(physicalShopAppointment.untilAvailableDate).getHours()}h{new Date(physicalShopAppointment.untilAvailableDate).getMinutes() != 0 ? new Date(physicalShopAppointment.untilAvailableDate).getMinutes() : "00"}
                                </div>
                            </div>
                        </>
                        
                    </BrowserView>
                    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                    <Row>
                        <Col xs={12}><h4>Adresse du magasin :</h4></Col>
                        <Col xs={12}>
                            <div>{physicalShop && physicalShop.name}</div>
                            <div>{physicalShop && physicalShop.location.address}</div>
                            <div>{physicalShop && physicalShop.location.zipCode} {physicalShop && physicalShop.location.town}</div>
                            <div>{physicalShop && physicalShop.location.country}</div>
                        </Col>
                        <Col xs={12}>
                            {
                                physicalShop && physicalShop.openingToday ? 
                                    <div style={{paddingTop: "5px", color: physicalShop.openingToday.closed && !physicalShop.openingToday.willOpened && !physicalShop.openingToday.willClosed ? "red": physicalShop.openingToday. willClosed || physicalShop.openingToday. willOpened ? "orange": "green" }}>
                                        <FaCircle/> {physicalShop.openingToday.closed && physicalShop.openingToday.hours == "" && !physicalShop.openingToday.willOpened && !physicalShop.openingToday.willClosed ? "Fermé" : physicalShop.openingToday.closed && !physicalShop.openingToday.willOpened && !physicalShop.openingToday.willClosed ? "Fermé - " : physicalShop.openingToday. willOpened ? "Ouvre prochainement - " : physicalShop.openingToday. willClosed ? "Ferme prochainement - " :  "Ouvert - "}<span style={{color: "black"}}>{physicalShop.openingToday.hours}</span>
                                    </div>  
                                : null
                            }                                                                                                   
                        </Col> 
                        {
                            physicalShop && physicalShop.openingToday && physicalShop.openingToday.publicHoliday ? 
                            <Col xs={12}>
                                <div style={{color: "#cdb900"}}>{physicalShop.openingToday.publicHolidayName} - Ces horaires peuvent varier.</div>
                            </Col> 
                            : null
                        }
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <div className="col-12 pro-details-quality">
                            <div className="col-12 pro-details-cart ml-0">
                                <Row>
                                    <Col xs={12}>
                                        <a
                                            href={null}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                            onClick={() => {
                                                openMap(physicalShop.location.coordinates.lat, 
                                                    physicalShop.location.coordinates.long)
                                            }}>
                                                M'y rendre
                                        </a>
                                    </Col> 
                                                                    
                                </Row>
                            </div>
                        </div>
                    </Row>
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                    {
                        physicalShopAppointment.bought ?
                        <Row>
                            <Col xs={12}>
                                <div style={{textAlign: 'center', paddingTop: "10px"}}>
                                    <div>
                                        <AiFillCheckCircle size="8rem" style={{color: "limegreen", paddingBottom: "30px"}}/>
                                    </div>
                                    RDV validé !
                                </div>
                            </Col>
                        </Row>  
                        : 
                        <Row>
                            <Col xs={12}>
                                <div>
                                    <p style={{textAlign: "center", fontSize: "18px", fontWeight:"700", marginBottom: "0px"}}>
                                        Mon QR Code
                                    </p>
                                    <p style={{textAlign: "center", fontSize: "14px", fontWeight:"700"}}>
                                        (à présenter lors du passage en caisse)
                                    </p>
                                </div>
                                <div style={{textAlign: "center", marginBottom: "40px"}}>
                                    <img src={physicalShopAppointment && physicalShopAppointment.qrCode} style={{textAlign: "center", width: "200px"}}/>
                                </div>
                            </Col>
                        </Row>  
                    }
                                        
                </div>
                    }
                <TokenSignInModal
                    show={openModalSignIN}
                    onHide={() => setOpenModalSignIN(false)}
                />
                
            </LayoutOne>
        </Fragment>
    );
};

PhysicalShopAppointment.propTypes = {
    location: PropTypes.object
};

export default PhysicalShopAppointment;