import PropTypes from "prop-types";
import React from "react";
import TrustBox from "../Trustbox";
import LanguageChangerFooter from "../header/sub-components/LanguageChangerFooter";
import {useTranslation} from "react-i18next";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`copyright ${spaceBottomClass ? spaceBottomClass : ""} ${
        colorClass ? colorClass : ""
      }`}
    >
      <p>
        &copy; {new Date().getFullYear()}{" "}
          The Running Collective<br /> {t('allrightreserved')}
      </p>
      <LanguageChangerFooter/>
      <TrustBox/>

    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
