import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import GuideSingleTwo from "../../components/product/GuideSingleTwo";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import axios from "axios";

const GuideGridTwo = ({
  type,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItems,
  wishlistItems,
  compareItems,
  sliderClassName,
  spaceBottomClass,
  colorClass,
  titlePriceClass,
  guideArticles,
  isSide
}) => {

  const [error, setError] = useState('');
  const [loading, setloading] = useState(false);

  return (
    <Fragment>
      {!loading ? guideArticles && guideArticles.length > 0 ? guideArticles.map((guideArticle, index) => {
        if(guideArticle) {
          return (
              <GuideSingleTwo
                  sliderClassName={sliderClassName}
                  spaceBottomClass={spaceBottomClass}
                  colorClass={colorClass}
                  guideArticle={guideArticle}
                  currency={currency}
                  addToCart={addToCart}
                  addToWishlist={addToWishlist}
                  addToCompare={addToCompare}
                  isSide={isSide}
                  index={index}
                
                  key={guideArticle.id}
                  titlePriceClass={titlePriceClass}
              />
          );
        }
      }) : <span>Aucun article disponible</span> : <span>Chargement en cours</span> }
      {error ? <span>Erreur durant le chargement des données</span> : null }
    </Fragment>
  );
};

GuideGridTwo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
  titlePriceClass: PropTypes.string,
  wishlistItems: PropTypes.array,
  shopType: PropTypes.string,
  origin: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {

  return {
    type: ownProps.type,
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuideGridTwo);
