import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import SnackbarCredits from "../Snackbar-credits";
import {useEffect} from "react";
import axios from "axios";
import {sleep} from "../../helpers/tools";
import Snackbar from "@mui/material/Snackbar";
import {useState} from "react";
import TextField from '@mui/material/TextField';
import CustomFileInput from '../CustomFileInput';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function ForecastLeagueImageModal({open, setOpen, league}) {
    const handleClose = () => {
        setOpen(false)
    }

    const fetchCreateLeague = (league, newLeagueImage) => {
        league.imageURL = newLeagueImage
        let query = 'https://public-front-api.therunningcollective.fr/forecast-league'
        axios.put(query, league,
            {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,})
            .then((res) => {
                setIsGameOK(false);
                setOpen(false);
                window.location.reload();
                window.prerenderReady = true
            })
            .catch((err) => {
                //window.location.href = "/404-not-found"
                console.log(err);
            })
    };

    const [isGameOK, setIsGameOK] = useState(false)
    const [newLeagueImage, setNewLeagueImage] = useState(league.imageURL)

    const handleCloseSnack = () => {
        setIsGameOK(false);
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical : 'top', horizontal : 'center' }}
                open={isGameOK}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                message={"Votre modification a bien été prise en compte !"}
                key={'top' + 'center'}
                style={{marginTop: "50px"}}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:"center", lineHeight: "24px"}}>
                        Modifier le logo de votre league ?
                    </Typography>
                    <div className="container-fluid" style={{marginTop: "20px", textAlign: "center"}}>                                     
                        <CustomFileInput setNewLeagueImage={setNewLeagueImage} existingImage={newLeagueImage}/>
                    </div>
                    <Row style={{marginTop: "30px", textAlign:"center"}}>
                        <Col>
                            <button
                                onClick={() => handleClose()}
                                style={{
                                    backgroundColor: "rgb(203, 200, 200)",
                                    color: "black",
                                    borderRadius:'5px',
                                    padding: "5px 40px",
                                    border: "none",
                                    fontSize: "15px",
                                    fontWeight: 600
                                }}>
                                Annuler
                            </button>
                        </Col>
                        <Col>
                            <button
                                onClick={() => fetchCreateLeague(league, newLeagueImage)}
                                style={{
                                    backgroundColor: "black",
                                    color: "white",
                                    borderRadius:'5px',
                                    padding: "5px 40px",
                                    border: "none",
                                    fontSize: "15px",
                                    fontWeight: 600
                                }}>
                                Valider
                            </button>
                        </Col>
                    </Row>

                </Box>
            </Modal>
        </div>
    );
}

ForecastLeagueImageModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    league: PropTypes.any,
};

export default ForecastLeagueImageModal;