import PropTypes from "prop-types";
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Swiper from "react-id-swiper";
import SectionTitleFive from "../../components/section-title/SectionTitleFive";
import GuideGridTwo from "./GuideGridTwo";
import {useTranslation} from "react-i18next";
import {BrowserView, isMobileOnly} from "react-device-detect";

const TabGuideEleven = ({
  spaceTopClass,
  spaceBottomClass,
  bgColorClass,
  category,
  sectionTitle,
  bgShape,
  colorClass,
  shopType,
  origin,
  filter,
  isSide,
  guideArticles
}) => {
  const [swiper, updateSwiper] = useState(null);
  const [swiper3, updateSwiper3] = useState(null);
  const { t } = useTranslation();

  const settings = {
    loop: false,
    grabCursor: true,
    observer: true,
    observeParents: true,
    breakpoints: {
      1024: {
        slidesPerView: 6
      },
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 2
      },
      320: {
        slidesPerView: 2
      }
    }
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const goNext3 = () => {
    if (swiper3 !== null) {
      swiper3.slideNext();
    }
  };
  const goPrev3 = () => {
    if (swiper3 !== null) {
      swiper3.slidePrev();
    }
  };

  
  return (
    <div
      className={`product-area product-area--style2 ${
        spaceTopClass ? spaceTopClass : ""
      } ${spaceBottomClass ? spaceBottomClass : ""} ${
        bgColorClass ? bgColorClass : ""
      }`}
      style={{marginLeft: isMobileOnly || isSide ? "0px" : "15px", marginRight: isMobileOnly || isSide ? "0px" : "15px"}}
    >
      <div className="container-fluid"
           style={{paddingLeft: isMobileOnly ? "0px" : "inherit", paddingRight: isMobileOnly ? "0px" : !isSide ? "-10px" : "inherit"}}
      >
        <div className="product-tab-slider-wrapper position-relative">
          <Tab.Container defaultActiveKey="newArrival">
            <div className="product-top-bar section-border mb-10">
              <SectionTitleFive titleText={sectionTitle} isSide={isSide} />
              <Nav
                variant="pills"
                className="product-tab-list-3 bg-gray-5 text-center"
              >
                <Nav.Item>
                  <Nav.Link eventKey="newArrival">
                    <h4>{t('newarrival')}</h4>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="saleItems">
                    <h4>{t('sales')}</h4>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="newArrival">
                <div className={"row" + isMobileOnly ? " tabproducteleven" : ""}>
                  <Swiper {...settings} getSwiper={updateSwiper}>
                    <GuideGridTwo
                      category={category}
                      type="homePage"
                      limit={8}
                      sliderClassName="swiper-slide"
                      colorClass={colorClass}
                      shopType={shopType}
                      origin={origin}
                      filter={filter}
                      isSide={isSide}
                      guideArticles={guideArticles}
                    />
                  </Swiper>
                  <BrowserView>
                    <div className="swiper-slider-navigation-wrapper product-slider-active">
                      <button
                          className="swiper-button-prev ht-swiper-button-nav"
                          onClick={goPrev}
                      >
                        <i className="pe-7s-angle-left" />
                      </button>
                      <button
                          className="swiper-button-next ht-swiper-button-nav"
                          onClick={goNext}
                      >
                        <i className="pe-7s-angle-right" />
                      </button>
                    </div>
                  </BrowserView>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

TabGuideEleven.propTypes = {
  bgColorClass: PropTypes.string,
  colorClass: PropTypes.string,
  bgShape: PropTypes.bool,
  category: PropTypes.string,
  sectionTitle: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  shopType: PropTypes.string,
  origin: PropTypes.string
};

export default TabGuideEleven;
