import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Col, Form, Row, Spinner} from "react-bootstrap";
import PropTypes from "prop-types";
import SnackbarCredits from "../Snackbar-credits";
import {useEffect} from "react";
import axios from "axios";
import {sleep} from "../../helpers/tools";
import Snackbar from "@mui/material/Snackbar";
import {useState} from "react";
import moment from "moment";
import TextField from '@mui/material/TextField';
import { getClientTokenData } from '../../App';
import { isMobileOnly } from 'react-device-detect';



function GenerateAmbassadorLinkModal({open, setOpen, currentLink}) {
    const [sendEmail, setSendEmail] = useState(true);
    const [link, setLink] = useState(currentLink);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [dynamicLink, setDynamicLink] = useState(false);

    const handleClose = () => {
        setOpen(false)
    }

    const submit = (link, setDynamicLink) => {
        setLoading(true)
        setError(false);
        axios.post("https://public-front-api.therunningcollective.fr/ambassador-dynamic-link", {
            link: link,
        }, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,}
        ).then((res) => {
            if (res.data && res.data != ""){
                setDynamicLink(res.data);
            } else {
                setError(true);
            }
            //window.location.reload()
        }).catch((err) => {
            setError(true);
            console.log(err);
            setLoading(false)
        })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobileOnly ? "100%" :  1000,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:"center", lineHeight: "24px", marginBottom: "30px"}}>
                        Générer un lien tracké : 
                    </Typography>
                    <div className='container'>
                        <Row style={{marginBottom: "30px"}} className='justify-content-center'>
                        <Col xs={isMobileOnly ? 12 : 8} className='text-right  align-self-center'>
                            <div style={{textAlign: "left"}}>Lien de la page :</div>
                                <input
                                    disabled={currentLink ? true : false}
                                    label="link"
                                    style={{backgroundColor: currentLink ? "lightgray" : "white", border: "1px solid black", borderRadius: "10px", fontSize: "15px"}}
                                    value={link} 
                                    onChange={e => setLink(e.target.value)}
                                />
                            </Col>                         
                        </Row>
                    </div> 

                    {
                        dynamicLink ?
                        <div className='container'>
                            <Row style={{marginBottom: "30px"}} className='justify-content-center'>
                                <Col xs={isMobileOnly ? 12 : 8} className='text-right  align-self-center'>
                                    <div style={{textAlign: "left"}}>Lien généré :</div>
                                    <input
                                        disabled
                                        label="link"
                                        style={{backgroundColor: "lightgray", border: "1px solid black", borderRadius: "10px", fontSize: "15px"}}
                                        value={dynamicLink} 
                                    />
                                </Col>                         
                            </Row>
                        </div>  : null
                    }

                    {
                        error ?
                        <Row style={{marginTop: "10px", textAlign:"center"}}>
                            <Col xs={12} style={{color: "red"}}>
                                Une erreur s'est produite, veuillez réessayer.
                            </Col>
                        </Row>
                        : null 
                    }
                    
                    {
                        loading && !dynamicLink && !error ?
                        <Row style={{marginTop: "10px", textAlign:"center"}}>

                            <Col xs={12}>
                                <div>Cela peut prendre qques secondes... :)</div>
                            </Col>

                            <Col xs={12}>
                                <div style={{textAlign: 'center'}}>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                    </Spinner>
                                </div>
                            </Col>
                        </Row>
                        :
                        <Row style={{marginTop: "30px", textAlign:"center"}}>
                            <Col xs={6}>
                                <button
                                    onClick={() => handleClose()}
                                    style={{
                                        backgroundColor: "rgb(203, 200, 200)",
                                        color: "black",
                                        borderRadius:'5px',
                                        padding: "5px 40px",
                                        border: "none",
                                        fontSize: "15px",
                                        fontWeight: 600
                                    }}>
                                    Fermer
                                </button>
                            </Col>
                            <Col xs={6}>
                                {
                                    sendEmail && (!link || link == "")
                                    ?
                                        <button
                                            disabled
                                            style={{
                                                backgroundColor: "gray",
                                                color: "white",
                                                borderRadius:'5px',
                                                padding: "5px 40px",
                                                border: "none",
                                                fontSize: "15px",
                                                fontWeight: 600
                                            }}>
                                            Générer
                                        </button>
                                    : 
                                        <button
                                            onClick={() => submit(link, setDynamicLink)}
                                            style={{
                                                backgroundColor: "black",
                                                color: "white",
                                                borderRadius:'5px',
                                                padding: "5px 40px",
                                                border: "none",
                                                fontSize: "15px",
                                                fontWeight: 600
                                            }}
                                            >
                                            Générer
                                        </button>
                                }                       
                            </Col>
                        </Row> 
                    }
                    

                </Box>
            </Modal>
        </div>
    );
}

GenerateAmbassadorLinkModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    forecast: PropTypes.any,
};

export default GenerateAmbassadorLinkModal;