import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import {isMobileOnly, isTablet} from "react-device-detect";
import Countdown from "react-countdown";
import { Col, Row } from "react-bootstrap";

const CurrentVoucherSingleTwo = ({
                                currentVoucher,
                                typeOfProducts,
                                merchantInfo,
                                currency,
                                sliderClassName,
                                spaceBottomClass,
                                colorClass,
                                titlePriceClass, isSide, index
                              }) => {

  const [userClick, setUserClick] = useState(false);

   // Renderer callback with condition
   const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return <span style={{color: "grey", fontWeight: 500, fontSize: "11px"}}>Se termine dans {days}j {hours}h {minutes}mn</span>;
  };

  const rendererSoon = ({ days, hours, minutes, seconds, completed }) => {
    return <span style={{color: "grey", fontWeight: 500, fontSize: "11px"}}>Commence dans {days}j {hours}h {minutes}mn</span>;
  };

  return (
      <Fragment>
        <div
            onClick={() => setUserClick(true)}
            className={isSide ? `col-xl-3 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }` : `col-xl-2 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }`}
            style={{cursor: "pointer", width: isMobileOnly ? "120px !important" : "230px !important", marginLeft: index == 0 && !isMobileOnly && isSide ? "-15px" : ""}}

        >
          <div
              style={{width: isMobileOnly ? "120px !important" : "230px !important", marginLeft: index == 0 && !isMobileOnly && isSide ? "-15px" : ""}}
              className={`sales-wrap-2 ${
                  spaceBottomClass ? spaceBottomClass : ""
              } ${colorClass ? colorClass : ""} `}
          >
            <div className="product-img" 
            style={{backgroundColor: "rgb(244, 246, 245)", 
              width: isMobileOnly ? "120px !important" : "230px !important", textAlign: "center", 
              height: isMobileOnly ? "120px !important" : "230px !important", overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"}}>
              <a href={merchantInfo && merchantInfo.url} target="_blank">
                {
                  userClick ?
                    <div style={{textAlign: "center"}}>
                      <div className="container" style={{fontSize: isMobileOnly ? "13px" : "16px"}}>
                      Code de réduction :  <br/>
                      <Row className="justify-content-center" style={{marginTop: isMobileOnly ? "5px" : "10px", marginBottom: isMobileOnly ? "5px" : "10px"}}>
                        <Col xs={isMobileOnly ? 8 : 6} style={{padding: "5px", backgroundColor: "white"}} className="align-self-center">{currentVoucher.name}</Col> 
                      </Row> 
                    </div>
                    <div style={{fontSize: isMobileOnly ? "10px" : "16px", lineHeight: "12px"}}>
                      {
                        currentVoucher.notOnSale ? " (Valable uniquement sur les produits non remisés)" : 
                        currentVoucher.onlyOnSale ? " (Valable uniquement sur les produits en réductions)" : null
                      }
                    </div>
                    </div>
                  : 
                  <img
                    className="default-img"
                    style={{width:"80%"}}
                    src={(merchantInfo && merchantInfo.logoUrl)}
                    alt={currentVoucher.name + " au meilleur prix !"}
                />
                }
                
              </a>
              

              {currentVoucher.isTemporary && !userClick ? (
                  <div className="product-img-badges">
                    <span className="purple" style={{backgroundColor : 'orange', color : 'white'}}>Temporaire</span>
                  </div>
              ) : (
                  ""
              )}

            </div>
            <div className="product-content-2">
              <div
                  className={`title-price-wrap-2 ${
                      titlePriceClass ? titlePriceClass : ""
                  }`}
              >
                <div className="price-2">
                  <h3 style={{lineHeight: "13px", fontSize: "16px"}}>
                  <a href={merchantInfo.url} target="_blank">
                      {currentVoucher.merchantName}
                    </a>
                  </h3>
                  <div style={{lineHeight: "15px", marginTop: "5px"}}>
                    <a href={merchantInfo.url} target="_blank">
                      <span style={{color: "grey", fontWeight: 500, fontSize: "11px"}}>-{currentVoucher.reductionAmount}{currentVoucher.voucherType} de réduction sur {typeOfProducts.map((product, index) => {
                        return(
                          <> {getProductName(product)}{typeOfProducts.length - 1 == index || typeOfProducts.length == 1 ? "." : typeOfProducts.length - 2 == index ? " et " : ", "} </>
                        )
                      })}
                      {
                        currentVoucher.notOnSale ? " (Valable uniquement sur les produits non remisés)" : 
                        currentVoucher.onlyOnSale ? " (Valable uniquement sur les produits en réductions)" : null
                      }
                      </span>
                    </a>
                  </div>
                  {
                    currentVoucher.isTemporary ?
                    <div style={{lineHeight: "15px", marginTop: "5px"}}>
                      <a href={currentVoucher.redirectLink} target="_blank">
                        <span style={{color: "grey", fontWeight: 500, fontSize: "11px"}}>
                          {
                            currentVoucher.availableSoon ? 
                            <Countdown date={currentVoucher.beginDate} renderer={rendererSoon}></Countdown>:
                                                    <Countdown date={currentVoucher.endDate} renderer={renderer}></Countdown>
                          }
                          </span>
                      </a>
                    </div>
                    : null 
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
  );
};

function getProductName(input){
  var output = ""

  if (input == "/products/shoes"){
    output = "les Chaussures"
  } else if (input == "/products/spikes"){
    output = "les Pointes"
  } else if (input == "/products/apparel"){
    output = "les Vêtements"
  } else if (input == "/products/nutrition"){
    output = "la Nutrition"
  } else if (input == "/products/electronic"){
    output = "l'Électronique"
  } else if (input == "/products/accessories"){
    output = "les Accessoires"
  }

  return output
}

CurrentVoucherSingleTwo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  currentVoucher: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
  titlePriceClass: PropTypes.string,
  wishlistItem: PropTypes.object,
  typeOfProducts: PropTypes.any,
};

export default CurrentVoucherSingleTwo;
