import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import SectionTitle from "../../components/section-title/SectionTitle";
import axios from "axios";
import PodcastFeaturedSingle from "../../components/podcast/PodcastFeaturedSingle";
import {useTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";

const PodcastsFeatured = ({ spaceTopClass, spaceBottomClass }) => {
  const [podcasts, setPodcasts] = useState({ podcasts: [] });
  const [error, setError] = useState('');
  const [loading, setloading] = useState(true);
  const { i18n } = useTranslation();

  const fetchData = () => {
    let query = 'https://public-front-api.therunningcollective.fr/home-podcasts'

    axios
        .get(query)
        .then((res) => {
          var result = res.data
          if (result && result.length > 0) {
            result = res.data
          }
          setPodcasts(result);
        })
        .catch((err) => {
          setError(err);
          window.prerenderReady = true
        })
        .finally(() => {
          setloading(false);
          window.prerenderReady = true
        });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
      i18n.language == "fr" ?
              <div
                  className={`mb-10 blog-area ${spaceTopClass ? spaceTopClass : ""} ${
                      spaceBottomClass ? spaceBottomClass : ""
                  }`}
                  style={{marginLeft: "15px", marginRight: "15px"}}
              >
                  <div className="container" style={{maxWidth: "100%"}}>
                      <Row>
                          <Col xs={10}>
                              <SectionTitle
                                  titleText="Nos Podcasts"
                                  positionClass="text-left"
                                  spaceClass="mb-10 mt-10"
                              />
                          </Col>
                          <Col xs={2} style={{textAlign: "right"}}>
                              <div style={{paddingTop: "10px", textAlign: "right"}}>
                                  <a href={process.env.PUBLIC_URL + 'podcasts/'}>Voir tous les podcasts</a>
                              </div>
                          </Col>
                      </Row>

                      {loading ? <div>Chargement en cours</div> : (podcasts && podcasts.length > 0) ?
                          <div className="row">
                              {podcasts.map((singlePost, index) => {
                                  if (index < 6){
                                      return (
                                          <PodcastFeaturedSingle singlePost={singlePost} key={singlePost.id} />
                                      );
                                  } else { return null }
                              })}</div>
                          : <span>Aucun podcats disponible</span>  }
                      {error ? <span>Erreur durant le chargement des données</span> : null }
                  </div>
              </div>
      : null
  );
};

PodcastsFeatured.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default PodcastsFeatured;
