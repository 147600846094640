import React, { useRef, useState } from 'react';
import { FaPencilAlt } from "react-icons/fa";
import {Spinner} from "react-bootstrap";

function CustomFileInput({setNewLeagueImage, existingImage}) {
  const [file, setFile] = useState(existingImage);
  const inputRef = useRef(null);
  const [loadingFile, setLoadingFile] = useState(false);

  const handleUploadClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    setLoadingFile(true)
    if (e.target.files) {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://public-front-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "forecast-leagues/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        setFile(result.message);
                        setNewLeagueImage(result.message);
                        setLoadingFile(false)
                    }
                )
            )
                .catch( err =>
                    {
                        console.log(err)
                        setLoadingFile(false)
                    }
                );
        }

    }

    // 🚩 do the file upload here normally...
  };

  return (
    <div>
      <div>Ajouter une image :</div>

      {
            loadingFile ?
            <div style={{textAlign: 'center'}}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                </Spinner>
            </div>
            : 
            <div onClick={handleUploadClick} className="container">
                <FaPencilAlt style={{
                    fontSize: "14px", 
                    position: "absolute",
                    marginLeft: "52px",
                    zIndex: "999999",
                    color: "grey"
                }}/>
                <img src={
                    file ? file : 
                    "https://app-trc-images.s3.eu-west-3.amazonaws.com/point-interrogation-vector-doodle-icone-logo-support-technique-aide_499739-885.avif"} 
                    style={{
                        position: "relative",
                        width: "70px", 
                        height: "70px", 
                        borderRadius: "50%",
                        border: "1px solid lightgray"
                    }}
                />
            </div>
        }
    
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
}

export default CustomFileInput;

