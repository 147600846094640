import PropTypes from "prop-types";
import React, {forwardRef, Fragment, useEffect, useState} from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import axios from "axios";
import {Col, Row, Spinner} from "react-bootstrap";
import {Divider} from "@mui/material";
import {
    AiFillWarning
} from "react-icons/all";
import {
    useHistory,
  } from 'react-router-dom';
import {getClientTokenData} from "../../App";
import TokenSignInModal from "../../components/product/TokenSignInModal";
import {FaCircle} from "react-icons/fa";
import {sleep} from "../../helpers/tools";
import {Capacitor} from "@capacitor/core";

const PhysicalShopBooking = ({location}) => {
    const {pathname} = location;
    const history = useHistory();

    const query = new URLSearchParams(location.search);
    const productSlug = query.get("productID")
    const reference = query.get("reference")
    const selectedSize = query.get("selectedSize")
    const physicalShopID = query.get("physicalShopID")
    const price = query.get("price")

    const [product, setProduct] = useState(null)
    const [physicalShop, setPhysicalShop] = useState(null)
    const [declination, setDeclination] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)
    const [openModalSignIN, setOpenModalSignIN] = useState(false)
    const [token, setToken] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        let token = getClientTokenData()
        setToken(token)
      }, [])

    const getPhysicalShop = (id) => {
        let query = 'https://api.therunningcollective.fr/api/public/physical-shop?physicalShopID=' + id
        axios
            .get(query)
            .then((res) => {
                setPhysicalShop(res.data)              
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getProduct = (slug, reference) => {
        let query = 'https://api.therunningcollective.fr/api/public/new-product?shortURL=' + slug
        axios
            .get(query)
            .then((res) => {
                setProduct(res.data)
                res.data.declinations && res.data.declinations.map(declination => {
                    if (declination.reference == reference){
                        setDeclination(declination)
                    }
                })
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
            })
    }

    const bookProduct = (productSlug, reference, selectedSize, physicalShopID, physicalShopName, productName, productImg, price, physicalShopImg) => {
        setLoadingButton(true);

        let query = 'https://api.therunningcollective.fr/api/protectedFront/physical-shop-book-product'
        axios.post(query, JSON.stringify(
            {
                productSlug,
                reference,
                selectedSize,
                physicalShopID, 
                physicalShopName,
                productName,
                productImg,
                price,
                physicalShopImg
            }
        ), {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,}
        )
            .then((res) => {
                sleep(2000)
                window.location.href = "/menu?value=21"
            }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                setLoadingButton(false)
                setError(error.response.data.msg)
            }
        });
    }

    useEffect(() => {
        console.log(physicalShopID)
        getProduct(productSlug, reference);
        getPhysicalShop(physicalShopID);
    }, [productSlug, reference, physicalShopID])

    return (
        <Fragment>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
                Réservation d'un produit
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible"
                       headerContainerClass="container-fluid"
                       headerPaddingClass="header-padding-2"
                       location={location}>
                {/* breadcrumb */}
                <Breadcrumb location={location}/>

                {
                        loading ?
                            <div style={{textAlign: 'center', marginTop: "80px"}}>
                                <Spinner animation="border" role="status" >
                                    <span className="visually-hidden"></span>
                                </Spinner>
                            </div>
                        : 
                        <div className="container-fluid" style={{paddingTop: Capacitor.getPlatform() === 'ios' ? "60px" : "20px"}}>
                    <Row>
                        <Col xs={4}>
                            {
                                declination && declination.firstImg ?
                                    <img style={{maxWidth:"120px"}} src={"https://img.therunningcollective.fr/" + declination.firstImg}/>
                                : null
                            }
                        </Col>
                        <Col xs={8}>
                            <div>{product && product.name}</div>
                            <div>Référence : {reference}</div>
                            <div>Sexe : {product && product.gender ? product.gender == "Men" ? "Homme" : product.gender == "Women" ? "Femme" : "Unisexe" : null}</div>
                            <div>Prix : {price}€</div>
                            <div>Taille : {selectedSize}</div>                          
                        </Col>
                    </Row>
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                    <Row>
                        <Col xs={12}><h4>Adresse du magasin :</h4></Col>
                        <Col xs={12}>
                            <div>{physicalShop && physicalShop.name}</div>
                            <div>{physicalShop && physicalShop.location.address}</div>
                            <div>{physicalShop && physicalShop.location.zipCode} {physicalShop && physicalShop.location.town}</div>
                            <div>{physicalShop && physicalShop.location.country}</div>
                        </Col>
                        <Col xs={12}>
                            {
                                physicalShop && physicalShop.openingToday ? 
                                    <div style={{paddingTop: "5px", color: physicalShop.openingToday.closed ? "red": physicalShop.openingToday. willClosed || physicalShop.openingToday. willOpened ? "orange": "green" }}>
                                        <FaCircle/> {physicalShop.openingToday.closed && physicalShop.openingToday.hours == "" ? "Fermé" : physicalShop.openingToday.closed ? "Fermé - " : physicalShop.openingToday. willOpened ? "Ouvre prochainement - " : physicalShop.openingToday. willClosed ? "Ferme prochainement - " :  "Ouvert - "}<span style={{color: "black"}}>{physicalShop.openingToday.hours}</span>
                                    </div>  
                                : null
                            }                                                                                                   
                        </Col> 
                        {
                            physicalShop && physicalShop.openingToday && physicalShop.openingToday.publicHoliday ? 
                            <Col xs={12}>
                                <div style={{color: "#cdb900"}}>{physicalShop.openingToday.publicHolidayName} - Ces horaires peuvent varier.</div>
                            </Col> 
                            : null
                        }
                    </Row>
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                    <Row>
                        <Col xs={12}><div><AiFillWarning size={22} style={{color: "red"}}/> Votre réservation est valable 48h ouvrées.</div></Col>
                    </Row>
                    {
                        loadingButton ?
                            <div style={{textAlign: 'center', marginTop: "20px"}}>
                                <Spinner animation="border" role="status" >
                                    <span className="visually-hidden"></span>
                                </Spinner>
                            </div>
                        : 
                        <div>
                            <Row style={{marginTop: "20px"}}>
                                <div className="col-12 pro-details-quality">
                                    <div className="col-12 pro-details-cart btn-hover ml-0">
                                        <Row>
                                            {
                                                error ? 
                                                <Col xs={12}>
                                                    <div style={{color: "red"}}>{error}</div>
                                                </Col>
                                                : <Col xs={12}>
                                                    <a
                                                        href={null}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                                        onClick={() => {
                                                            token ?
                                                                bookProduct(productSlug, reference, selectedSize, physicalShopID, 
                                                                    physicalShop.name, product.name, declination.firstImg, price,
                                                                    physicalShop.mapLogo) : setOpenModalSignIN(true)
                                                        }}>
                                                            Réserver
                                                        </a>
                                                </Col>  
                                            }
                                                                            
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                            <Row style={{marginTop: "20px"}}>
                                <div className="col-12 pro-details-quality">
                                    <div className="col-12 pro-details-cart btn-hover ml-0">
                                        <Row>                                 
                                            <Col xs={12}>
                                                <a
                                                    href={null}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{borderRadius: "5px", width: "100%", lineHeight: "16px", backgroundColor: "black", border: "4px solid #4fa0a6"}}
                                                    onClick={() => (setLoadingButton(true), history.goBack())}>
                                                        Annuler
                                                    </a>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        
                    }
                    
                </div>
                    }
                <TokenSignInModal
                    show={openModalSignIN}
                    onHide={() => setOpenModalSignIN(false)}
                />
                
            </LayoutOne>
        </Fragment>
    );
};

PhysicalShopBooking.propTypes = {
    location: PropTypes.object
};

export default PhysicalShopBooking;