import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import SnackbarCredits from "../Snackbar-credits";
import {useEffect} from "react";
import axios from "axios";
import {sleep} from "../../helpers/tools";
import Snackbar from "@mui/material/Snackbar";
import {useState} from "react";
import moment from "moment";
import TextField from '@mui/material/TextField';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function ForecastModal({open, setOpen, forecast, user, forecastLeagueID}) {
    const handleClose = () => {
        setOpen(false)
    }

    const forecastAttendee = (forecast, user, pseudo, forecastLeagueID) => {
        let query = 'https://public-front-api.therunningcollective.fr/attendee-forecast?forecastID=' + forecast.id
        if(forecastLeagueID){
            query = query + "&forecastLeagueID=" + forecastLeagueID
        }
        axios.post(query, {
            id: forecast.id,
            userID: user.id,
            pseudo: pseudo,
        }, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,}
        ).then((res) => {
            setIsGameOK(true)
            sleep(2000)
            window.location.assign(process.env.PUBLIC_URL + "/forecast/"+ forecast.id)
            //window.location.reload()
        }).catch((err) => {
            console.log(err);
        })
    }

    const [isGameOK, setIsGameOK] = useState(false)
    const [date, setDate] = useState('')
    const [pseudo, setPseudo] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (forecast) {
            var beginDay = new Date(forecast.beginDate).getDate()
            var beginDateFormat = moment(forecast.beginDate);
            var beginMonthStr = beginDateFormat.locale("fr").format('MMM')

            var endDay = new Date(forecast.endDate).getDate()
            var endDateFormat = moment(forecast.endDate);
            var endMonthStr = endDateFormat.locale("fr").format('MMM')
            setDate(beginDay + " " + beginMonthStr + " au " + endDay + " " + endMonthStr + " " + beginDateFormat.year())
        }
    }, [forecast])

    const handleCloseSnack = () => {
        setIsGameOK(false);
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical : 'top', horizontal : 'center' }}
                open={isGameOK}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                message={"Votre participation a bien été prise en compte !"}
                key={'top' + 'center'}
                style={{marginTop: "50px"}}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:"center", lineHeight: "24px"}}>
                        Êtes-vous sûr de vouloir participer à ce jeu ?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:"14px", textAlign:"center"}}>
                        {forecast.name}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:"14px", textAlign:"center"}}>
                        {forecast.town}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:"14px", textAlign:"center"}}>
                        {date}
                    </Typography>
                    <div style={{marginTop: "25px"}}>
                        <TextField
                            style={{width: "100%"}}
                            id="outlined-helperText"
                            label="Pseudo"
                            helperText="Maximum 12 caractères"
                            inputProps={{ maxLength: 12 }}
                            onChange={(e) => setPseudo(e.target.value)}
                        />
                    </div>
                    {
                        error ?
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{color : "red", fontSize:"14px", textAlign:"center"}}>
                            {error}
                        </Typography>
                     : null
                    }
                    <Row style={{marginTop: "30px", textAlign:"center"}}>
                        <Col>
                            <button
                                onClick={() => handleClose()}
                                style={{
                                    backgroundColor: "rgb(203, 200, 200)",
                                    color: "black",
                                    borderRadius:'5px',
                                    padding: "5px 40px",
                                    border: "none",
                                    fontSize: "15px",
                                    fontWeight: 600
                                }}>
                                Annuler
                            </button>
                        </Col>
                        <Col>
                            <button
                                onClick={() => !pseudo || pseudo == "" ? setError("Veuillez rentrer votre pseudo"): (setError(null), forecastAttendee(forecast, user, pseudo, forecastLeagueID))}
                                style={{
                                    backgroundColor: "black",
                                    color: "white",
                                    borderRadius:'5px',
                                    padding: "5px 40px",
                                    border: "none",
                                    fontSize: "15px",
                                    fontWeight: 600
                                }}>
                                Valider
                            </button>
                        </Col>
                    </Row>

                </Box>
            </Modal>
        </div>
    );
}

ForecastModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    forecast: PropTypes.any,
};

export default ForecastModal;