import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {setActiveSizesSort} from "../../helpers/product";
import {isMobileOnly} from "react-device-detect";
import qs from "query-string";

const ShopCategories = ({ location, currentQuery, setCurrentQuery, categories, getSortParams }) => {
    const [hide, setHide] = useState(false)
    const [isSmallScreen, setSmallScreen] = useState(false);
    const [display, setDisplay] = useState('none')

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-tag-categories button"
    );

    filterButtons.forEach(item => {
        item.classList.remove("active");
    });
    if (query && query.category) {
        let category = query.category.split('_');
        category.map(category => {
            var element = document.getElementById(category);
            if (element){
                element.classList.add("active")
            }
        })
    }

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        }
    }, [])

    useEffect(() => {
        window.addEventListener("resize", function() {
            if (window.matchMedia("(min-width: 992px)").matches) {
                setSmallScreen(false)
            }
        })
    }, [])

    function constructAddQueryPath(query, category) {
        if (query && query.category) {
            return query.category + "_" + category
        }
        return category
    }

    function constructRemoveQueryPath(query, sizeToRemove) {
        if (query && query.category) {
            let category = query.category.split('_');
            category = category.filter(category => {
                return category !== sizeToRemove;
            })
            let newSizeQuery = ""
            category.map((category, index) => {
                if (index == 0){
                    newSizeQuery += category
                } else {
                    newSizeQuery +=  "_" + category
                }
            })
            return newSizeQuery
        }
        return null
    }

    function onHide(){
        setHide(!hide)
    }

    useEffect(() => {
        if (hide) {
            setDisplay('none')
        } else {
            setDisplay('block')
        }
    }, [hide])

    useEffect(() => {
        if (isMobileOnly || isSmallScreen){
            setHide(false)
        }
    }, [isSmallScreen])
    return (
        <div className="sidebar-widget mt-10">
            {
                isMobileOnly || isSmallScreen ?
                    <div>
                        <h4 className="pro-sidebar-title">Catégories &nbsp;
                        </h4>
                        <div className="sidebar-widget-tag-categories mt-10" style={{display: display, maxHeight: '200px', overflow: 'auto'}}>
                            {categories ? (
                                <ul>
                                    {categories.map((category, key) => {
                                        return (
                                            <li key={key}>
                                                <button
                                                    id={category}
                                                    onClick={e => {
                                                        if (e.currentTarget.classList.contains('active')) {
                                                            getSortParams("category", "");
                                                            setCurrentQuery({ ...currentQuery, category: constructRemoveQueryPath(query, category)});
                                                        } else {
                                                            getSortParams("category", category);
                                                            setCurrentQuery({ ...currentQuery, category: constructAddQueryPath(query, category)});
                                                        }
                                                        setActiveSizesSort(e);
                                                    }}
                                                >
                                                    {category}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                "Aucune taille disponible"
                            )}
                        </div>

                    </div>
                    :
                    <div>
                        <h4 className="pro-sidebar-title">Catégories &nbsp; </h4>
                        <div className="sidebar-widget-tag-categories mt-10"  style={{maxWidth: '320px', maxHeight: '300px', overflow: 'auto'}}>
                            {categories ? (
                                <ul>
                                    {categories.map((category, key) => {
                                        return (
                                            <li key={key}>
                                                <button
                                                    id={category}
                                                    onClick={e => {
                                                        if (e.currentTarget.classList.contains('active')) {
                                                            getSortParams("category", "");
                                                            setCurrentQuery({ ...currentQuery, category: constructRemoveQueryPath(query, category)});
                                                        } else {
                                                            getSortParams("category", category);
                                                            setCurrentQuery({ ...currentQuery, category: constructAddQueryPath(query, category)});
                                                        }
                                                        setActiveSizesSort(e);
                                                    }}
                                                >
                                                    {category}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                "Aucune catégorie disponible"
                            )}
                        </div>
                    </div>

            }
        </div>
    );
};

let weights = {
    'XXS':1,
    'XS':2,
    'S':3,
    'M':4,
    'L':5,
    'XL':6,
    'XXL':7,
    '2XL': 8
};

export function sortSize(array){
    return array.sort((a,b)=>{
        if(typeof(a)=="number" && typeof(b)=="number")
            return a-b;
        else
            return weights[a]-weights[b]
    });
}

ShopCategories.propTypes = {
    getSortParams: PropTypes.func,
    category: PropTypes.array,
    currentQuery: PropTypes.object,
    setCurrentQuery: PropTypes.func,
    location: PropTypes.string,
};

export default ShopCategories;
