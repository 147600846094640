import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import SectionTitle from "../../components/section-title/SectionTitle";
import axios from "axios";
import {useTranslation} from "react-i18next";
import RaceCard from "../../components/races/RaceCard";
import moment from "moment";
import ReactGA from "react-ga4";
import {Col, Row} from "react-bootstrap";
import {isMobileOnly} from "react-device-detect";

const HomeRacesFeatured = ({ spaceTopClass, spaceBottomClass, shopType }) => {
  const [races, setRaces] = useState({ races: [] });
  const [error, setError] = useState('');
  const [loading, setloading] = useState(true);
  const [sponsoredRaces, setSponsoredRaces] = useState([]);
    const [newInitialRaces, setNewInitialRaces] = useState([]);
    const { i18n } = useTranslation();

    const getSponsoredRaces = () => {
        let query = 'https://public-front-api.therunningcollective.fr/races?page=1&isSponsored=true'
        if (shopType && shopType !== ""){
            query = query + '&type=' + shopType
        }

        ReactGA.event({
            category: 'Races',
            action: 'Get sponsored races'
        });

        axios.post(query, null, {}).then((res) => {
            if (res.data){
                setSponsoredRaces(res.data.races)
            } else {
                setSponsoredRaces([])
            }
        })
    };

    const getInitialRaces = (shopType) => {
        let query = 'https://public-front-api.therunningcollective.fr/home-races'

        if (shopType && shopType !== ""){
            query = query + '&type=' + shopType
        }

        ReactGA.event({
            category: 'Races',
            action: 'Get all races'
        });

        axios.get(query, null)
            .then((res) => {
                setRaces(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
            setloading(false);
        });
    };

  useEffect(() => {
      getInitialRaces(shopType);
      getSponsoredRaces(shopType)
  }, [shopType]);

    useEffect(() => {
        if(races && races.length > 0){
            if (sponsoredRaces && sponsoredRaces.length > 0){
                var newInitialRaces = sponsoredRaces.concat(races)
            } else {
                var newInitialRaces = races
            }
            const pp = newInitialRaces.filter((ele, ind) => ind === newInitialRaces.findIndex(elem => elem.id === ele.id))
            setNewInitialRaces(pp)
        }
    }, [races, sponsoredRaces])

  return (
      i18n.language == "fr" ?
              <div
                  className={`mb-10 blog-area ${spaceTopClass ? spaceTopClass : ""} ${
                      spaceBottomClass ? spaceBottomClass : ""
                  }`}
                  style={{marginLeft: isMobileOnly ? "0px" : "15px", marginRight: isMobileOnly ? "0px" : "15px"}}
              >
                  <div className="container" style={{maxWidth: "100%"}}>

                      <Row>
                          <Col xs={10}>
                              <SectionTitle
                                  titleText="Les prochaines courses"
                                  positionClass="text-left"
                                  spaceClass="mb-10 mt-10"
                              />
                          </Col>
                          {
                              isMobileOnly ?
                                  <Col xs={2} style={{textAlign: "right"}}>
                                      <div style={{paddingTop: "15px", textAlign: "right"}}>
                                          <a href={process.env.PUBLIC_URL + 'races?page=1'}>Voir</a>
                                      </div>
                                  </Col>
                                  :
                                  <Col xs={2} style={{textAlign: "right"}}>
                                      <div style={{paddingTop: "10px", textAlign: "right"}}>
                                          <a href={process.env.PUBLIC_URL + 'races?page=1'}>Voir toutes les courses</a>
                                      </div>
                                  </Col>
                          }

                      </Row>
                      {loading ? <div>Chargement en cours</div> : (newInitialRaces && newInitialRaces.length > 0) ?
                          <Row>
                              {
                                  newInitialRaces.map((race, index) => {
                                      if (index < (isMobileOnly ? 2 : 6)){
                                          return (
                                              <Col xl={2} xs={6} style={{paddingBottom: isMobileOnly ? "0px" : "30px"}}>
                                                  <RaceCard race={race}/>
                                              </Col>
                                          );
                                      } else {
                                          return null
                                      }
                                  })}
                          </Row>
                          : <span>Aucune course disponible</span>  }
                      {error ? <span>Erreur durant le chargement des données</span> : null }
                  </div>
              </div>
      : null
  );
};

HomeRacesFeatured.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default HomeRacesFeatured;
