import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {getHexaColorFromName, setActiveSizesSort} from "../../helpers/product";
import {isMobileOnly} from "react-device-detect";
import qs from "query-string";
import axios from "axios";
import {useTranslation} from "react-i18next";

const ShopTagColor = ({ location, currentQuery, setCurrentQuery, colors, getSortParams }) => {
  const [hide, setHide] = useState(false)
  const [isSmallScreen, setSmallScreen] = useState(false);
  const [display, setDisplay] = useState('none')
  const [avalaibleColors, setAvalaibleColors] = useState([]);
  const { t } = useTranslation()

  const fetchColorsData = () => {
    let query = 'https://public-front-api.therunningcollective.fr/detailed-colors?headCategory=all'

    axios
        .get(query)
        .then((res) => {
          setAvalaibleColors(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
  };

  useEffect(() => {
    fetchColorsData();
  }, []);

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-color button"
  );

  filterButtons.forEach(item => {
    item.classList.remove("active");
  });
  if (query && query.colors) {
    let sizes = query.colors.split('_');
    sizes.map(size => {
      var element = document.getElementById(size);
      if (element){
        element.classList.add("active")
      }
    })
  }

  useEffect(() => {
    const matchResult = window.matchMedia("(max-width: 992px)");
    if (matchResult.matches) {
      setSmallScreen(true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", function() {
      if (window.matchMedia("(min-width: 992px)").matches) {
        setSmallScreen(false)
      }
    })
  }, [])

  function constructAddQueryPath(query, size) {
    if (query && query.colors) {
      return query.colors + "_" + size
    }
    return size
  }

  function constructRemoveQueryPath(query, sizeToRemove) {
    if (query && query.colors) {
      let sizes = query.colors.split('_');
      sizes = sizes.filter(size => {
        return size !== sizeToRemove;
      })
      if (sizes.length > 0) {
        let newSizeQuery = ""
        sizes.map((size, index) => {
          if (index == 0){
            newSizeQuery += size
          } else {
            newSizeQuery +=  "_" + size
          }
        })
        return newSizeQuery
      } else {
        return null
      }

    }
    return null
  }

  function onHide(){
    setHide(!hide)
  }

  useEffect(() => {
    if (hide) {
      setDisplay('none')
    } else {
      setDisplay('block')
    }
  }, [hide])

  useEffect(() => {
    if (isMobileOnly || isSmallScreen){
      setHide(false)
    }
  }, [isSmallScreen])
  return (
    <div className="sidebar-widget mt-10">
      {
        isMobileOnly || isSmallScreen ?
            <div>
              <h4 className="pro-sidebar-title">{t('colors')} &nbsp;
              </h4>
              <div className="sidebar-widget-tag-color mt-10" style={{display: display, maxHeight: '200px', overflow: 'auto'}}>
                {colors ? (
                    <ul>
                      {colors.map((color, key) => {
                        return (
                            <li key={key} style={{textAlign: 'center'}}>
                              <button
                                  id={color.name}
                                  style={{backgroundColor: getHexaColorFromName(color.name, avalaibleColors),
                                    border: '1px solid #333'
                                  }}
                                  onClick={e => {
                                    if (e.currentTarget.classList.contains('active')) {
                                      getSortParams("color", "");
                                      setCurrentQuery({ ...currentQuery, colors: constructRemoveQueryPath(query, color.name)});
                                    } else {
                                      getSortParams("color", color.name);
                                      setCurrentQuery({ ...currentQuery, colors: constructAddQueryPath(query, color.name)});
                                    }
                                    setActiveSizesSort(e);
                                  }}
                              >
                              </button>
                              <div>
                                {t(color.name)}
                              </div>
                            </li>
                        );
                      })}
                    </ul>
                ) : (
                    "Aucune couleur disponible"
                )}
              </div>

            </div>
            :
            <div>
              <h4 className="pro-sidebar-title">{t('colors')} </h4>
              <div className="sidebar-widget-tag-color mt-10">
                {colors ? (
                    <ul>
                      {colors.map((color, key) => {
                        return (
                            <li key={key} style={{textAlign: 'center'}}>
                              <button
                                  id={color.name}
                                  style={{backgroundColor: getHexaColorFromName(color.name, avalaibleColors),
                                    border: '1px solid #333'
                                  }}
                                  onClick={e => {
                                    if (e.currentTarget.classList.contains('active')) {
                                      getSortParams("color", "");
                                      setCurrentQuery({ ...currentQuery, colors: constructRemoveQueryPath(query, color.name)});
                                    } else {
                                      getSortParams("color", color.name);
                                      setCurrentQuery({ ...currentQuery, colors: constructAddQueryPath(query, color.name)});
                                    }
                                    setActiveSizesSort(e);
                                  }}
                              >
                              </button>
                              <div>
                                {t(color.name)}
                              </div>
                            </li>
                        );
                      })}
                    </ul>
                ) : (
                    "Aucune taille disponible"
                )}
              </div>
            </div>

      }
    </div>
  );
};

ShopTagColor.propTypes = {
  getSortParams: PropTypes.func,
  colors: PropTypes.array,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
  location: PropTypes.string,
};

export default ShopTagColor;
