import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import axios from "axios";
import {Col, Row, Spinner} from "react-bootstrap";
import ReactGA from "react-ga4";
import {Alert, Nav, Tab} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const MyAccount = ({ location }) => {
  const { pathname } = location;
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [])

  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const getProfile = () => {
    ReactGA.event({
      category: 'User',
      action: 'Get profile information'
    });
    setLoading(true)

    let query = 'https://public-front-api.therunningcollective.fr/profile'
    axios.get(query, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
      }
      ,}
      )
        .then((res) => {
          GetImprovedUserData(res.data)
          setUser(res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err);
          setLoading(false)
        })
  };

  const updateInformations = (user) => {
    ReactGA.event({
      category: 'User',
      action: 'Update profile informations'
    });
    setLoading(true)


    user.trsanctions = []
    user.rewards = []

    const config = { headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`} };
    let query = 'https://public-front-api.therunningcollective.fr/front-user'
    axios.put(query, JSON.stringify(user), config).then((res) => {
      document.location.reload()
      setLoading(false)

    })
        .catch((err) => {
          console.log(err.response)
          setLoading(false)
          setErrorMessage(err.response.data.msg)
        })
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (password === confirmPassword){
      setErrorPassword(false)
    } else {
      setErrorPassword(true)
    }
  }, [password, confirmPassword]);

  return (
    <Fragment>
      <MetaTags>
        <title>{t('myaccount')}</title>
        <meta name="description" content={t('myaccount')} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content={"The Running Collective | "+ t('myaccount')} />
        <meta property="og:description" content={t('myaccount')} />
        <meta property="og:image" content="https://img.therunningcollective.fr/head-04.jpg" />
        <meta name="robots" content="noindex, nofollow"/>
      </MetaTags>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>{t('home')}</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        {t('myaccount')}
      </BreadcrumbsItem>
      <LayoutOne
          headerContainerClass="container-fluid"
          headerPaddingClass="header-padding-2"
          headerTop="visible"
      location={location}>
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="myaccount-area pb-20 pt-20">
          <div className="container">
            <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
              <h1 className="h3 mb-3">{t('myprofile')}</h1>
              <Row>
                <Col md="5" xl="3">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="profile">{t('persoinfo')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="password">{t('password')} </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col md="7" xl="9">
                {
                  loading ?
                  <div style={{textAlign: 'center'}}>
                      <Spinner animation="border" role="status">
                          <span className="visually-hidden"></span>
                      </Spinner>
                  </div>
                  : 
                  <Tab.Content>
                    <Tab.Pane eventKey="profile">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="myaccount-wrapper">
                            <Accordion defaultActiveKey="0">
                              <Card className="single-my-account mb-20">
                                  <Card.Body>
                                    <div className="myaccount-info-wrapper">
                                      <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                          <div className="billing-info">
                                            <label>{t('firstname')}</label>
                                            <input type="text"
                                                   defaultValue={user ? user.firstName : ""}
                                                   onChange={(event => {setUser({...user, firstName: event.target.value})})}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                          <div className="billing-info">
                                            <label>{t('lastname')}</label>
                                            <input type="text"
                                                   defaultValue={user ? user.name : ""}
                                                   onChange={(event => {setUser({...user, name: event.target.value})})}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                          <div className="billing-info">
                                            <label>Email</label>
                                            <div>{user ? user.email : ""}</div>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                          <div className="billing-info">
                                            <label>{t('phonenumber')}</label>
                                            <input type="text"
                                                   defaultValue={user ? user.phoneNumber : ""}
                                                   onChange={(event => {setUser({...user, phoneNumber: event.target.value})})}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                          <div className="button-box">
                                            <div className="login-toggle-btn">
                                              <Row>
                                                <Col xs={1} style={{textAlign: 'center'}}>
                                                  <input className="input-checkbox-newsletter-my-account"
                                                         type="checkbox"
                                                         defaultChecked={user ? user.newsletter : false}
                                                         onChange={(event => {setUser({...user, newsletter: event.target.checked})})}
                                                  />
                                                </Col>
                                                <Col xs={11}>
                                                  <label className="ml-10">{t('newslettersubscribed')}</label>
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {errorMessage !== ""
                                          ?
                                          <span>{t('majfailed')} {errorMessage}</span>
                                          : null}
                                      <div className="billing-back-btn">
                                        <div className="billing-btn">
                                          <button type="submit" onClick={() => updateInformations(user)}>{t('modify')}</button>
                                        </div>
                                      </div>
                                    </div>
                                  </Card.Body>
                              </Card>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="password">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="myaccount-wrapper">
                            <Accordion defaultActiveKey="0">
                              <Card className="single-my-account mb-20">
                                <Card.Body>
                                  <div className="myaccount-info-wrapper">
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12">
                                        <div className="billing-info">
                                          <label>{t('oldpassword')}</label>
                                          <input type="password"
                                                 onChange={(event => {setUser({...user, oldPassword: event.target.value})})}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="billing-info">
                                          <label>{t('newpassword')}</label>
                                          <input type="password"
                                                 onChange={(e => {setUser({...user, password: e.target.value});
                                                   setPassword(e.target.value)})}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="billing-info">
                                          <label>{t('passwordconfirm')}</label>
                                          <input type="password"
                                                 onChange={(e => {setConfirmPassword(e.target.value)})}
                                          />
                                          {errorPassword ?
                                              <span>{t('passwordnotsame')}</span>
                                              : null}
                                        </div>
                                      </div>
                                    </div>
                                    {errorMessage !== ""
                                        ?
                                        <span>{t('majfailed')} {errorMessage}</span>
                                        : null}
                                    <div className="billing-back-btn">
                                      <div className="billing-btn">
                                        {errorPassword ?
                                            <button type="submit" disabled>{t('modify')}</button>
                                            :
                                            <button type="submit" onClick={ () => {
                                              updateInformations(user)}}>{t('modify')}</button>
                                        }

                                      </div>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  }
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

MyAccount.propTypes = {
  location: PropTypes.object
};

export default MyAccount;


//creating function to load ip address from the API
export const GetImprovedUserData = async (user) => {
  var url = "https://pro.ip-api.com/json/?key=Y1hZznfd2inZrpm&fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query"
  const resData = await axios.get(url)
  if (resData.data) {
    resData.data.location = window.location.pathname
    resData.data.firstName = user.firstName
    resData.data.lastName = user.name
    resData.data.email = user.email
    resData.data.phoneNumber = user.phoneNumber
    resData.data.login = user.login
  }
  var query = "https://public-front-api.therunningcollective.fr/data"
  axios.post(query, JSON.stringify(resData.data))
}