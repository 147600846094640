import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ReactGA from "react-ga4";
import Card from "react-bootstrap/Card";
import {Button, Col, Row} from "react-bootstrap";
import axios from "axios";
import {GetImprovedUserData} from "./MyAccount";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Countdown from "react-countdown";
import {sleep} from "../../helpers/tools";
import ToggleSwitch from "../../components/ToogleButton";
import {isDesktop, isMobileOnly, isTablet} from "react-device-detect";
import {useTranslation} from "react-i18next";
import moment from "moment";

const Appointments = ({ location }) => {
  const { pathname } = location;
    const { i18n } = useTranslation()
    const [origin, setOrigin] = useState("fr")

    useEffect(() => {
        setOrigin(i18n.language)
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [])

  const [newAppointments, setNewAppointments] = useState(true);
  const [displayedAppointments, setDiplayedAppointments] = useState({});

  const getAppointments = (newAppointments) => {
    ReactGA.event({
      category: 'Appointments',
      action: 'Get data'
    });

    let query = 'https://public-front-api.therunningcollective.fr/old-physical-shop-booked'
    if (newAppointments){
        query = 'https://public-front-api.therunningcollective.fr/physical-shop-booked'
    }
    
    axios.post(query, null, {
      headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
      }
      ,})
      .then((res) => {
        setDiplayedAppointments(res.data ? res.data.reverse() : [])
      })
      .catch((err) => {
          console.log(err);
      })
  };

  useEffect(() => {
    getAppointments(newAppointments);
  }, [newAppointments]);

  const rendererSoon = ({ days, hours, minutes, seconds, completed }) => {
    return <span style={{color: "red", fontWeight: 500, fontSize: "13px"}}>Expire dans {days}j {hours}h {minutes}mn</span>;
  };

  const onGameChange = (checked) => {
      setNewAppointments(checked);
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Mes RDVs</title>
        <meta
          name="description"
          content="Retrouvez ici tous les jeux concours disponibles"
        />
        <meta name="robots" content="noindex, nofollow"/>
      </MetaTags>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Accueil</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Mes rendez-vous
      </BreadcrumbsItem>
      <LayoutOne
          headerContainerClass="container-fluid"
          headerPaddingClass="header-padding-2"
          headerTop="visible"
      location={location}>
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="error-area pt-20 pb-40">
          <div className="container">
            <Row className="pb-20">
              <Col md={10}>
                <h1 className="h3 mb-3">Mes Rendez-vous</h1>
              </Col>
              <Col md={2} style={{paddingLeft: 0}}>
                  <ToggleSwitch
                      id="newsletter"
                      checked={newAppointments}
                      onChange={onGameChange}
                  />
              </Col>
            </Row>
            <Row>
              {displayedAppointments && displayedAppointments.length > 0 ?
                  displayedAppointments.map(appointment => {
                    return (
                        <Col xs={isMobileOnly ? 6 : 3} style={{textAlign: 'center', marginTop: "20px", cursor: "pointer"}}
                          onClick={() => (newAppointments ? window.location.href = "/physical-shop-appointment/" + appointment.id : null)}>
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src= {appointment.cover}
                                style={{width: '100%'}}/>
                                {
                                    appointment.productImg != "" ?
                                      <img style={{width:"100%"}} src={appointment.productImg != "" ? appointment.productImg : "https://img.therunningcollective.fr/shoes-background-1-jpeg-jpg-jpg"}/>
                                    : 
                                    <div>
                                        <img style={{width:"100%", position: "absolute", zIndex: "10"}} src={appointment.productImg != "" ? "https://img.therunningcollective.fr/" + appointment.productImg : "https://img.therunningcollective.fr/shoes-background-1-jpeg-jpg-jpg"}/>
                                        <img style={{maxWidth:"50px", position: "relative", zIndex: "20", marginLeft: "35px", marginTop: "35px", borderRadius: "10px"}} src={appointment.physicalShopImg != "" ? "https://img.therunningcollective.fr/" + appointment.physicalShopImg : "https://img.therunningcollective.fr/shoes-background-1-jpeg-jpg-jpg"}/>                                                       
                                    </div>
                                }

                                <Card.Body>
                                <Card.Title>{appointment.physicalShopName}</Card.Title>
                                <Card.Text>
                                  {
                                    appointment.productName != "" && appointment.productReference != "" ?
                                    <>
                                        <div style={{fontSize:"13px"}}>{appointment.productName}</div>
                                        <div>Référence : {appointment.productReference}</div>
                                        <div>Taille : {appointment.selectedSize}</div>
                                        <div>Prix : {appointment.price}€</div>
                                    </>
                                    : 
                                    <div>
                                        Rendez-vous en magasin
                                    </div>
                                  }                                                        
                                  {
                                    appointment.productName != "" && appointment.productReference != "" && newAppointments ?
                                    <div><Countdown date={appointment.untilAvailableDate} renderer={rendererSoon}/></div> : newAppointments?
                                    <div>
                                        {moment(appointment.untilAvailableDate).format("DD/MM/YYYY").toString()} à {moment(appointment.untilAvailableDate).hours()}h{moment(appointment.untilAvailableDate).minutes() != 0 ? moment(appointment.untilAvailableDate).minutes() : "00"}
                                    </div> : null
                                  }
                                  {
                                    !newAppointments && appointment.bought ?
                                        <div style={{color:"green"}}>Validé <span style={{color:"black"}}>- {appointment.price}</span>  <img src={"https://img.therunningcollective.fr/logo-trcoin-svg-short.svg"} style={{maxWidth: "16px", paddingBottom: "2px"}}/></div>
                                    :  !newAppointments ? <div style={{color:"orange"}}>Annulé</div> : null
                                  }
                                </Card.Text>
                              </Card.Body>
                            </Card>
                        </Col>
                    )
                  })
                  :
                  <div className="col-md-12" style={{textAlign: 'center'}}>
                      <span>Aucun RDV disponible... :)</span>
                  </div>}
            </Row>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

Appointments.propTypes = {
  location: PropTypes.object
};

export default Appointments;
