import PropTypes from "prop-types";
import React, {forwardRef, Fragment, useEffect, useState} from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import axios from "axios";
import {Col, Row, Spinner} from "react-bootstrap";
import {Divider} from "@mui/material";
import {
    AiFillWarning,
    IoCalendarNumberOutline
} from "react-icons/all";
import {
    useHistory,
  } from 'react-router-dom';
import {getClientTokenData} from "../../App";
import TokenSignInModal from "../../components/product/TokenSignInModal";
import AppointmentValidationModal from "../../components/product/AppointmentValidationModal";
import {FaCircle} from "react-icons/fa";
import {sleep} from "../../helpers/tools";
import {Capacitor} from "@capacitor/core";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment";
import { isMobileOnly } from "react-device-detect";

const PhysicalShopBookingClinc = ({location}) => {
    const {pathname} = location;
    const history = useHistory();

    const query = new URLSearchParams(location.search);
    const productSlug = query.get("productID")
    const reference = query.get("reference")
    const selectedSize = query.get("selectedSize")
    const physicalShopID = query.get("physicalShopID")
    const price = query.get("price")

    const [product, setProduct] = useState(null)
    const [physicalShop, setPhysicalShop] = useState(null)
    const [declination, setDeclination] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)
    const [openModalSignIN, setOpenModalSignIN] = useState(false)
    const [openModalConfirm, setOpenModalConfirm] = useState(false)
    const [token, setToken] = useState(null)
    const [error, setError] = useState(null)
    const [selectedHour, setSelectedHour] = useState(null)
    const [arrayOfNextDays, setArrayOfNextDays] = useState([])
    const [selectedDay, setSelectedDay] = useState(null)

    const [expanded, setExpanded] = useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        setSelectedHour(null)
      };

    useEffect(() => {
        let token = getClientTokenData()
        setToken(token)
      }, [])
      
    const getPhysicalShop = (id) => {
        let query = 'https://public-front-api.therunningcollective.fr/physical-shop?id=' + id
        axios
            .get(query)
            .then((res) => {
                setLoading(false)
                setPhysicalShop(res.data)              
            })
            .catch((err) => {
                console.log(err);
            })
    }

            
    const handleSelectedHour = (selectedHour, currentSelectedHour, currentSelectedDay) => {
        if(selectedHour == currentSelectedHour){
            setSelectedHour(null)
            setSelectedDay(null)
        } else if (selectedHour && selectedHour !== ""){
            setSelectedHour(selectedHour)
            setSelectedDay(currentSelectedDay + " " + selectedHour)
            setError(null)
        }
    }

    const book = (physicalShopID, physicalShopName, physicalShopImg, selectedHour, selectedDay) => {
        if (!selectedHour || selectedHour == ""){
            setError("Veuillez sélectionner un horaire")
        } else {
            setError(null)
            setLoadingButton(true);
            setOpenModalConfirm(true)
        }
        console.log(selectedDay)
        console.log(moment(selectedHour))
    }

    const getPhysicalShopNextDays = (id) => {
        let query = 'https://public-front-api.therunningcollective.fr/physical-shop-next-days?id=' + id
        axios
            .get(query)
            .then((res) => {
                setLoading(false);
                setArrayOfNextDays(res.data)              
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getPhysicalShop(physicalShopID);
        getPhysicalShopNextDays(physicalShopID)
    }, [productSlug, reference, physicalShopID])

    return (
        <Fragment>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
            <IoCalendarNumberOutline size={"1.5em"} style={{marginTop: "-5px"}}/> &nbsp;Prise de Rendez-vous
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible"
                       headerContainerClass="container-fluid"
                       headerPaddingClass="header-padding-2"
                       location={location}>
                {/* breadcrumb */}
                <Breadcrumb location={location}/>

                {
                        loading ?
                            <div style={{textAlign: 'center', marginTop: "80px"}}>
                                <Spinner animation="border" role="status" >
                                    <span className="visually-hidden"></span>
                                </Spinner>
                            </div>
                        : 
                    <div className={isMobileOnly ? ""  : "container"} style={{paddingTop: "20px"}}>
                    <Row>
                        <Col xs={12}><h4>Choisissez la date du rendez-vous</h4></Col>
                        <Col xs={12} className="container">
                            {
                                arrayOfNextDays && arrayOfNextDays.length > 0 && arrayOfNextDays.map((day,index) => {
                                    return(
                                            <Accordion style={{marginBottom: "10px", borderRadius: "5px", border: "1px solid black"}} expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    sx={{
                                                        backgroundColor: "#f7f7f7",
                                                        borderRadius: "5px",
                                                        borderBottom: "1px solid black"
                                                    }}
                                                >
                                                    <Typography>{day.name}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    sx={{
                                                        paddingTop: "0px"
                                                    }}>
                                                    <Row>
                                                        {
                                                            day.hours.map(el => {
                                                                return(
                                                                    <Col xs={3}>
                                                                        <div
                                                                            onClick={(e) => handleSelectedHour(el, selectedHour, day.name)}
                                                                            style={{
                                                                                textAlign: "center",
                                                                                paddingBottom: "5px",
                                                                                paddingTop: "8px",
                                                                                paddingRight: "1px",
                                                                                border: el == selectedHour ? "3px solid black" : "1px solid black",
                                                                                borderRadius: "5px",
                                                                                marginTop: "16px",
                                                                                backgroundColor: el == selectedHour ? "#4fa0a6" : "white",
                                                                                color: el == selectedHour ? "white" : "black"
                                                                            }}
                                                                        >
                                                                                {el}
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                </AccordionDetails>
                                            </Accordion>
                                    )
                                })
                            }
                        </Col>
                        <Col xs={12}>
                            <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                        </Col>
                        <Col xs={12}><h4>Magasin</h4></Col>
                        <Col xs={12}>
                            <div>{physicalShop && physicalShop.name}</div>
                            <div>{physicalShop && physicalShop.location.address}</div>
                            <div>{physicalShop && physicalShop.location.zipCode} {physicalShop && physicalShop.location.town}</div>
                            <div>{physicalShop && physicalShop.location.country}</div>
                        </Col>
                        <Col xs={12}>
                            {
                                physicalShop && physicalShop.openingToday ? 
                                    <div style={{paddingTop: "5px", color: physicalShop.openingToday.closed ? "red": physicalShop.openingToday. willClosed || physicalShop.openingToday. willOpened ? "orange": "green" }}>
                                        <FaCircle/> {physicalShop.openingToday.closed && physicalShop.openingToday.hours == "" ? "Fermé" : physicalShop.openingToday.closed ? "Fermé - " : physicalShop.openingToday. willOpened ? "Ouvre prochainement - " : physicalShop.openingToday. willClosed ? "Ferme prochainement - " :  "Ouvert - "}<span style={{color: "black"}}>{physicalShop.openingToday.hours}</span>
                                    </div>  
                                : null
                            }                                                                                                   
                        </Col> 
                        {
                            physicalShop && physicalShop.openingToday && physicalShop.openingToday.publicHoliday ? 
                            <Col xs={12}>
                                <div style={{color: "#cdb900"}}>{physicalShop.openingToday.publicHolidayName} - Ces horaires peuvent varier.</div>
                            </Col> 
                            : null
                        }
                    </Row>
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                    {
                        loadingButton ?
                            <div style={{textAlign: 'center', marginTop: "20px", marginBottom: "50px"}}>
                                <Spinner animation="border" role="status" >
                                    <span className="visually-hidden"></span>
                                </Spinner>
                            </div>
                        : 
                        <div>
                            <Row style={{marginTop: "20px"}}>
                                <div className="col-12 pro-details-quality">
                                    <div className="col-12 pro-details-cart btn-hover ml-0">
                                        <Row>
                                            {
                                                error ? 
                                                <Col xs={12}>
                                                    <div style={{color: "red"}}>{error}</div>
                                                </Col>
                                                : <Col xs={12}>
                                                    <a
                                                        href={null}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                                        onClick={() => {
                                                            token ?
                                                                book(physicalShopID, physicalShop.name, physicalShop.mapLogo, selectedHour, selectedDay) : setOpenModalSignIN(true)
                                                        }}>
                                                            Confirmer mon rendez-vous
                                                        </a>
                                                </Col>  
                                            }
                                                                            
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                            <Row style={{marginTop: "20px", marginBottom: "50px"}}>
                                <div className="col-12 pro-details-quality">
                                    <div className="col-12 pro-details-cart btn-hover ml-0">
                                        <Row>                                 
                                            <Col xs={12}>
                                                <a
                                                    href={null}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{borderRadius: "5px", width: "100%", lineHeight: "16px", backgroundColor: "black", border: "4px solid #4fa0a6"}}
                                                    onClick={() => (setLoadingButton(true), history.goBack())}>
                                                        Annuler
                                                    </a>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        
                    }
                    
                </div>
                    }
                    {
                        openModalConfirm ?
                            <AppointmentValidationModal
                                physicalShopID={physicalShopID}
                                physicalShopName={physicalShop.name}
                                physicalShopImg={physicalShop.mapLogo}
                                selectedHour={selectedHour}
                                selectedDay={selectedDay}
                                show={openModalConfirm}
                                onHide={() => (setOpenModalConfirm(false), setLoadingButton(false))}
                            />                  
                        : null
                    }
                
                <TokenSignInModal
                    show={openModalSignIN}
                    onHide={() => setOpenModalSignIN(false)}
                />
                
            </LayoutOne>
        </Fragment>
    );
};

PhysicalShopBookingClinc.propTypes = {
    location: PropTypes.object
};

export default PhysicalShopBookingClinc;