import PropTypes from "prop-types";
import React from "react";
import { multilanguage } from "redux-multilanguage";
import {useTranslation} from "react-i18next";
import i18n from "../../helpers/i18n";
import moment from "moment";
import {a} from "react-router-dom";

const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul style={{textAlign: "center"}}>
          <li>
            <a href={process.env.PUBLIC_URL + "/products?genders=Men&page=1"}>
              {" "}
              {t('men')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="mega-menu">
              <li className="mega-4">
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&page=1"}>
                      {t('shoes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&shopType=running&page=1"}>
                      {t('road')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&shopType=trail&page=1"}>
                      {t('trail')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Claquettes&page=1"}>
                      {t('thongs')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&page=1"}>
                      {t('allshoes')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/spikes?page=1"}>
                      {t('spikes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Cross&page=1"}>
                      {t('cross')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Demi-fond&page=1"}>
                      {t('middledistance')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Sprint&page=1"}>
                      {t('sprint')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Autres&page=1"}>
                      {t('others')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?page=1"}>
                      {t('allspikes')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&page=1"}>
                      {t('apparel')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Débardeurs&page=1"}>
                      {t('singlet')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Tee-shirts&page=1"}>
                      {t('teeshirt')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Vestes&page=1"}>
                      {t('hoodies')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Cuissards&page=1"}>
                      {t('halftigh')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Shorts&page=1"}>
                      {t('short')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Collants&page=1"}>
                      {t('longtight')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Equipe%20de%20France&page=1"}>
                      {t('frenchteam')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&page=1"}>
                      {t('allproducts')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('accessories')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                      {t('hat')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                      {t('socks')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?genders=Men&category=Gants&page=1"}>
                      {t('gloves')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                      {t('gps')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                      {t('food')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                      {t('electronic')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                      {t('bag')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('allaccesories')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&page=1"}>
                      {t('brands')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=adidas&page=1"}>
                      adidas
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Asics&page=1"}>
                      Asics
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Hoka%20One%20One&page=1"}>
                      Hoka One One
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=New%20Balance&page=1"}>
                      New Balance
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Nike&page=1"}>
                      Nike
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=On%20Running&page=1"}>
                      On Running
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Puma&page=1"}>
                      Puma
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Saucony&page=1"}>
                      Saucony
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-img">
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&page=1"}>
                      <img
                          src={
                              process.env.PUBLIC_URL +
                              "/assets/img/test-h-menu.jpg"
                          }
                          alt=""
                          style={{maxWidth: '200px'}}
                      />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + "/products?genders=Women&page=1"}>
              {" "}
              {t('women')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="mega-menu">
              <li className="mega-4">
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&page=1"}>
                      {t('shoes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&shopType=running&page=1"}>
                      {t('road')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&shopType=trail&page=1"}>
                      {t('trail')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Claquettes&page=1"}>
                      {t('thongs')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&page=1"}>
                      {t('allshoes')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/spikes?page=1"}>
                      {t('spikes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Cross&page=1"}>
                      {t('cross')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Demi-fond&page=1"}>
                      {t('middledistance')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Sprint&page=1"}>
                      {t('sprint')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Autres&page=1"}>
                      {t('others')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?page=1"}>
                      {t('allspikes')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&page=1"}>
                      {t('apparel')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Brassières&page=1"}>
                      {t('bra')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Débardeurs&page=1"}>
                      {t('singlet')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Tee-shirts&page=1"}>
                      {t('teeshirt')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Vestes&page=1"}>
                      {t('hoodies')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Shorts&page=1"}>
                      {t('short')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Collants&page=1"}>
                      {t('longtight')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Equipe%20de%20France&page=1"}>
                      {t('frenchteam')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women"}>
                      {t('allproducts')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('accessories')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                      {t('hat')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                      {t('socks')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?genders=Women&category=Gants&page=1"}>
                      {t('gloves')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                      {t('gps')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                      {t('food')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                      {t('electronic')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                      {t('bag')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('allaccesories')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&page=1"}>
                      {t('brands')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=adidas&page=1"}>
                      adidas
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Asics&page=1"}>
                      Asics
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Hoka%20One%20One&page=1"}>
                      Hoka One One
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=New%20Balance&page=1"}>
                      New Balance
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Nike&page=1"}>
                      Nike
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=On%20Running&page=1"}>
                      On Running
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Puma&page=1"}>
                      Puma
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Saucony&page=1"}>
                      Saucony
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-img">
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&page=1"}>
                      <img
                          src={
                              process.env.PUBLIC_URL +
                              "/assets/img/femme-menu-1.jpg"
                          }
                          alt=""
                          style={{maxWidth: '200px'}}
                      />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + "/products/accessories"}>
              {t('accessories')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="submenu">
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                      {t('hat')}
                    </a>
                  </li>

                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                      {t('socks')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Gants&page=1"}>
                      {t('gloves')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Lunettes%20de%20vitesse&page=1"}>
                      {t('speedglasses')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                      {t('bag')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                      {t('gps')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('allaccesories')}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <a href={process.env.PUBLIC_URL + "/products/nutrition"}>
              {t('food')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="submenu">
              <li>
                <ul>
                  <li className="mega-menu-title">
                  <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Boissons%20énergétiques&page=1"}>
                  {t('fooddrink')}
                    </a>
                  </li>

                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Gels%20énergétiques&page=1"}>
                      {t('foodgel')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Barres%20énergétiques&page=1"}>
                      {t('foodbar')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Packs&page=1"}>
                      {t('foodpack')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                      {t('allfood')}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          {
            i18n.language == "fr" ?
              <li>
                <a href={"https://blog.therunningcollective.fr"}>
                  {t('blog')}
                </a>
              </li>: null
          }

          {
            i18n.language == "fr" ?
                <li>
                  <a href={process.env.PUBLIC_URL + "/races?page=1&startDate="+
                      moment().locale('fr').format('L')+"&endDate="+
                      moment().add(1, 'M').locale('fr').format('L')}>
                    {t('races')}
                  </a>
                </li>
                : null
          }

        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object
};

export default multilanguage(NavMenu);
