import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Swiper from "react-id-swiper";
import BrandLogoOneSingle from "../../components/brand-logo/BrandLogoOneSingle";
import axios from "axios";
import SectionTitle from "../../components/section-title/SectionTitle";
import {useTranslation} from "react-i18next";
import {isMobileOnly} from "react-device-detect";

const BrandLogoSliderOne = ({ spaceBottomClass, spaceTopClass, setMerchands }) => {
  const [avalaibleMerchands, setAvalaibleMerchands] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const fetchMerchandsData = () => {
    var lang = ""
    if(i18n.language == "be"){
      lang = "fr"
    } else {
      lang = i18n.language
    }
    let query = 'https://public-front-api.therunningcollective.fr/merchants?lang='+lang

    axios
        .get(query)
        .then((res) => {
          if (res.data.length > 0) {
            var data = shuffleArray(res.data);
            setAvalaibleMerchands(data);
            setMerchands(data);
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false)
        })
  };

  useEffect(() => {
    fetchMerchandsData();
  }, []);

  const settings = {
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    grabCursor: true,
    breakpoints: {
      1024: {
        slidesPerView: 8
      },
      768: {
        slidesPerView: 4
      },
      640: {
        slidesPerView: 3
      },
      320: {
        slidesPerView: 2
      }
    }
  };

  return (
    <div
      className={`brand-logo-area ${
        spaceBottomClass ? spaceBottomClass : ""
      }  ${spaceTopClass ? spaceTopClass : ""}`}
      style={{marginLeft: isMobileOnly ? "0px" : "15px", marginRight: isMobileOnly ? "0px" : "15px"}}
    >
      <div className="container-fluid">
        <SectionTitle
            titleText={t('ourpartners')}
            positionClass="text-left"
            spaceClass="mb-30"
        />
        <div className="brand-logo-active">
          {loading ? null
              :
              <Swiper {...settings}>
                {avalaibleMerchands &&
                avalaibleMerchands.map((single, key) => {
                  return (
                      <BrandLogoOneSingle
                          data={single}
                          key={key}
                          sliderClassName="swiper-slide"
                          spaceBottomClass="mb-30"
                      />
                  );
                })}
              </Swiper>
          }

        </div>
      </div>
    </div>
  );
};

export function shuffleArray(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

BrandLogoSliderOne.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default BrandLogoSliderOne;
